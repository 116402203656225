import React from "react";
import { Button } from "react-bootstrap";

interface PopUpNotificationProps {
  message: string;
  onClose: () => void;
}

const PopUpNotification: React.FC<PopUpNotificationProps> = ({
  message,
  onClose,
}) => {
  return (
    <div
      className="d-flex position-fixed w-100 h-100 justify-content-center align-items-center"
      style={{ background: "rgba(0,0,0,0.1)", backdropFilter: "blur(3px)" }}
    >
      <div className="bg-white rounded col-10 col-lg-3 position-relative">
        {/* <div className="w-100 d-flex justify-content-end">
          <Button
            className="btn-dark bg-transparent border-0 text-dark"
            style={{ zIndex: "1000", bottom: 120, right: 0 }}
            // onClick={onClose}
          >
            <img src={closeIcon} alt="closeIcon" />
          </Button>
        </div> */}
        <h4 className="pt-3">{message}</h4>
        <div className="d-flex justify-content-center pt-3 pb-4">
          <Button
            className="d-flex align-items-center btn btn-dark px-5"
            onClick={onClose}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PopUpNotification;
