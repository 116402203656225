import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { uploadContent } from "../api/api";
import styles from "./styles/AddOrEditPartMaterialOption.module.css";
import { BsImage } from "react-icons/bs";
interface AddOrEditMaterialOptionType {
  partAndMaterialHandleChangeOption: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleImageChange: (name: "thumbnail" | "texture", url: string) => void;
  editOptionCopy: any;
  editStepCopy: any;
}

const AddOrEditPartMaterialOptionForm = ({
  partAndMaterialHandleChangeOption,
  handleImageChange,
  editOptionCopy,
  editStepCopy,
}: AddOrEditMaterialOptionType) => {
  const thumbnailRef = useRef<any>();
  const textureRef = useRef<any>();

  const onThumbnailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    var files = e.target.files;
    if (files && files.length > 0) {
      try {
        const thumbnailUrl = await uploadContent(files[0]);
        handleImageChange("thumbnail", thumbnailUrl);
      } catch (error) {
        console.error(error);
        alert(`Unable to upload Image due to error: ${JSON.stringify(error)}`);
      }
    }
  };
  const onTextureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    var files = e.target.files;
    if (files && files.length > 0) {
      try {
        const textureUrl = await uploadContent(files[0]);
        handleImageChange("texture", textureUrl);
      } catch (error) {
        console.error(error);
        alert(`Unable to upload Image due to error: ${JSON.stringify(error)}`);
      }
    }
  };
  return (
    <div>
      <Row className="mt-2 mb-4 px-2 align-items-center mb-3">
        <Col xs={3} className="p-0 ">
          <label htmlFor="name" className="m-0 ps-1">
            Name* :
          </label>
        </Col>
        <Col className="pe-1">
          <input
            type="text"
            className="pl-1 w-100"
            id="name"
            name="name"
            onChange={(e) => {
              partAndMaterialHandleChangeOption(e);
            }}
            value={editOptionCopy?.name}
          />
        </Col>
      </Row>
      {editStepCopy?.type === "part_selector" && (
        <Row className="mt-2 px-2 align-items-center mb-3">
          <Col xs={3} className="p-0 ps-1">
            <label htmlFor="name" className="m-0">
              Product id* :
            </label>
          </Col>
          <Col className="pe-1">
            <input
              type="text"
              className="pl-1 w-100"
              id="pid"
              name="pid"
              onChange={(e) => {
                partAndMaterialHandleChangeOption(e);
              }}
              value={editOptionCopy?.rules[0].pid}
            />
          </Col>
        </Row>
      )}
      {/* <Row className="mt-2 px-2 align-items-center mb-3">
        <Col xs={3} className="p-0 ">
          <label htmlFor="name" className="m-0">
            Thumbnail* :
          </label>
        </Col>
        <Col>
          <input
            type="text"
            className="pl-1 w-100"
            id="thumbnail"
            name="thumbnail"
            onChange={(e) => {
              console.log(e.target.value);
              partAndMaterialHandleChangeOption(e);
            }}
            disabled={
              editOptionCopy?.name.replaceAll(" ", "").toLowerCase() === "none"
            }
            value={
              editOptionCopy?.name.replaceAll(" ", "").toLowerCase() !== "none"
                ? editOptionCopy?.thumbnail
                : "https://i.ibb.co/whY06SN/none.jpg"
            }
          />
        </Col>
      </Row> */}
      <Row>
        <Col xs={12} md={6}>
          <div className={`${styles.inputContainer} border position-relative`}>
            <input
              ref={thumbnailRef}
              type="file"
              accept="image/*"
              className={`${styles.imageInput}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onThumbnailChange(e)
              }
            />

            <img
              src={
                editOptionCopy?.thumbnail || "./assets/images/placeholder.png"
              }
              alt=""
              className="img-fluid w-100 h-100"
            />

            <div
              className={`${styles.uploadBtnContainer} position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center`}
            >
              <button
                className={`btn btn-dark`}
                onClick={() => thumbnailRef.current.click()}
              >
                <BsImage />
              </button>
            </div>
            <div className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-end">
              <p
                className="h6 px-2 py-1 rounded-2 text-white"
                style={{ background: "rgba(0,0,0,0.4)" }}
              >
                {editOptionCopy?.thumbnail ? "Thumbnail" : "Upload Thumbnail"}
              </p>
            </div>
          </div>
        </Col>
        {editStepCopy?.type === "material_selector" && (
          <Col xs={12} sm={6}>
            <div
              className={`${styles.inputContainer} border position-relative`}
            >
              <input
                ref={textureRef}
                type="file"
                accept="image/*"
                className={`${styles.imageInput}`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onTextureChange(e)
                }
              />

              <img
                src={
                  editOptionCopy?.rules[0]?.textureUrl ||
                  "./assets/images/placeholder.png"
                }
                alt=""
                className="img-fluid w-100 h-100"
              />

              <div
                className={`${styles.uploadBtnContainer} position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center`}
              >
                <button
                  className={`btn btn-dark`}
                  onClick={() => textureRef.current.click()}
                >
                  <BsImage />
                </button>
              </div>
              <div className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-end">
                <p
                  className="h6 px-2 py-1 rounded-2 text-white"
                  style={{ background: "rgba(0,0,0,0.4)" }}
                >
                  {editOptionCopy?.rules[0]?.textureUrl
                    ? "Texture"
                    : "Upload Texture"}
                </p>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AddOrEditPartMaterialOptionForm;
