import React, { useEffect, useState } from "react";
import SideBarCard from "../components/SideBarCard";
import { useAppSelector } from "../hooks/hooks";
import { StepType } from "../types";
import { Col } from "react-bootstrap";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { TOGGLE_ADDING_STEP } from "../store/userConfiguration/configurationSlice";
import { useParams } from "react-router-dom";
import { getVariants, patchProductConfiguratorRules } from "../api/api";
import VariantsCard from "../components/VariantsCard";
import useIsPortrait from "../hooks/useIsPortrait";
import { SET_CONFIGURATOR_RULES } from "../store/product/productSlice";

interface SideBarProps {
  configData: any;
  isBottomBarOpen: boolean;
  setIsBottomBarOpen: (isBottomBarOpen: boolean) => void;
  isEditMode?: boolean;
  sessionId:string;
}

const SideBar: React.FC<SideBarProps> = ({
  configData,
  isBottomBarOpen,
  setIsBottomBarOpen,
  isEditMode,
  sessionId
}) => {
  const dispatch = useDispatch();
  const userConfiguration = useAppSelector(
    (state) => state.configuration.userConfiguration
  );
  const product = useAppSelector((state) => state.product.product);
  const { id } = useParams();
  const disabledFeatures = useAppSelector(
    (state) => state.configuration.disabledFeatures
  );
  const [variantsStep, setVariantsStep] = useState<any>(undefined);
  const isPortrait = useIsPortrait();
  useEffect(() => {
    if (id && product) {
      (async () => {
        const variantsArray: any[] = await getVariants(id);
        const variantsArrayMapped = variantsArray.map((variant: any) => {
          const { name, three_dee_model, thumbnail } = variant;
          const { glb } = three_dee_model;
          return { name, thumbnail, rules: [{ glb }] };
        });
        const defaultVariant = {
          name: "Default",
          thumbnail: product.thumbnail,
          rules: [{ glb: product.three_dee_model.glb }],
        };
        const variantsStepTemp = {
          name: "Variant",
          type: "variant_selector",
          node: ["Scene"],
          options: [defaultVariant, ...variantsArrayMapped],
        };
        setVariantsStep(variantsStepTemp);
      })();
    }
  }, [id, product]);

  useEffect(() => {
    if (disabledFeatures.length > 0 && configData) {
      const tempConfigData = { ...configData };
      const tempSteps = [...tempConfigData.steps];

      for (let i of tempSteps) {
        if (disabledFeatures.includes(i.id)) {
          const disableStepIndex = tempSteps.indexOf(i);
          const tempStep = { ...tempSteps[disableStepIndex] };
          tempStep.isHidden = true;
          tempSteps[disableStepIndex] = tempStep;
        }
      }
      tempConfigData.steps = [...tempSteps];
      dispatch(SET_CONFIGURATOR_RULES(tempConfigData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledFeatures, dispatch]);

  const handleMoveStep = async (
    stepIndex: number,
    direction: "up" | "down"
  ) => {
    const tempConfigData = { ...configData };
    const tempSteps = [...tempConfigData.steps];
    let requiredTempStep;
    if (direction === "up") {
      requiredTempStep = tempSteps[stepIndex - 1];
      tempSteps[stepIndex - 1] = tempConfigData.steps[stepIndex];
    } else {
      requiredTempStep = tempSteps[stepIndex + 1];
      tempSteps[stepIndex + 1] = tempConfigData.steps[stepIndex];
    }
    tempSteps[stepIndex] = requiredTempStep;
    tempConfigData.steps = tempSteps;
    if (id) {
      try {
        await patchProductConfiguratorRules(id, tempConfigData);
        dispatch(SET_CONFIGURATOR_RULES(tempConfigData));
      } catch (error) {
        console.error(error);
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <div
      className={`position-fixed bg-white border ${
        isBottomBarOpen && "sidebarActive"
      }`}
      style={{ zIndex: "100", right: 0 }}
      id="sidebar"
    >
      <Col
        className="position-absolute d-flex d-md-none justify-content-center w-100"
        style={{ zIndex: "101", left: 0, top: "-5%" }}
      >
        <button
          className="btn border"
          style={{ background: "#dbdbdb" }}
          onClick={() => {
            setIsBottomBarOpen(!isBottomBarOpen);
          }}
        >
          {isBottomBarOpen ? (
            <FaChevronDown width={14} height={14} className="mx-1" />
          ) : (
            <FaChevronUp width={14} height={14} className="mx-1" />
          )}
        </button>
      </Col>
      <Col
        className={`sidebarCardCol pt-2 pt-md-0 pb-3 ${
          isEditMode && "editingCustomizations"
        } ${isEditMode && isPortrait && "sidebarCardColMobileEdit"}`}
      >
        <h5 className="text-start p-3 pt-4 font-weight-bold">Customizations</h5>
        {variantsStep && variantsStep.options.length > 1 && (
          <VariantsCard step={variantsStep} sessionId={sessionId} />
        )}
        {
          console.log(userConfiguration,"userConfiguration")
        }
        {configData &&
          configData.steps.length > 0 &&
          configData.steps.map((step: StepType, index: number) => (
            <SideBarCard
              key={index}
              step={step}
              userConfiguration={userConfiguration}
              cardIndex={index}
              isEditMode={isEditMode}
              handleMoveStep={handleMoveStep}
              sessionId={sessionId}
              isLast={index === configData.steps.length - 1}
            />
          ))}
        {isPortrait && isEditMode && (
          <div className="d-flex flex-column px-3">
            <button
              className="btn border-0 btn-dark mt-4 w-100"
              style={{ background: "#A2DB35" }}
              onClick={() => {
                dispatch(TOGGLE_ADDING_STEP());
              }}
            >
              Add Customization
            </button>
          </div>
        )}
      </Col>
      {isEditMode && !isPortrait && (
        <div className="d-flex flex-column border-top px-3">
          <button
            className="btn border-0 btn-dark mt-4 w-100"
            style={{ background: "#A2DB35" }}
            onClick={() => {
              dispatch(TOGGLE_ADDING_STEP());
            }}
          >
            Add Customization
          </button>
        </div>
      )}
    </div>
  );
};

export default SideBar;
