import React from "react";
import { Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import closeIcon from "../assets/icons/close.svg";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

interface CustomerDetailsProps {
  onNext: () => void;
  setPhoneNumber: (phoneNumber: string) => void;
  phoneNumber: string;
  setEmail: (email: string) => void;
  email: string;
  name: string;
  setName: (name: string) => void;
  address: any;
  setAddress: (address: any) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  onClose: () => void;
  isGeneratingOtp: boolean;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  onNext,
  setPhoneNumber,
  phoneNumber,
  setEmail,
  email,
  name,
  setName,
  address,
  setAddress,
  quantity,
  setQuantity,
  onClose,
  isGeneratingOtp,
}) => {
  return (
    <div
      className="position-fixed start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center "
      style={{ background: "rgba(0,0,0,0.5)", backdropFilter: "blur(3px)" }}
    >
      <div className="bg-white rounded col-10 col-lg-5 position-relative">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h4 className="ps-3 pt-3 pb-0 mb-0">Quotation Form</h4>
          <Button
            className="btn-dark bg-transparent border-0 text-dark"
            style={{ zIndex: "1000", bottom: 120, right: 0 }}
            onClick={onClose}
          >
            <img src={closeIcon} alt="closeIcon" />
          </Button>
        </div>
        <div className="px-5 w-100">
          <div className="mt-3">
            <div className="form-group">
              <label className="w-100 mb-1 d-flex text-left">Name</label>
              <input
                type="email"
                className="form-control"
                id="name"
                placeholder="Enter name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </div>
          </div>
          <div className="mt-3">
            <div className="form-group">
              <label htmlFor="email" className="w-100 mb-1 d-flex text-left">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>
          </div>
          <div className="mt-3">
            <div className="form-group">
              <label
                htmlFor="GooglePlacesAutocomplete"
                className="w-100 mb-1 d-flex text-left"
              >
                Address
              </label>
              <GooglePlacesAutocomplete
                selectProps={{
                  value: address,
                  onChange: setAddress,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: "in",
                  },
                }}
                apiKey="AIzaSyAYpScaBMlSl5JYNWU9EP1w_-Wd41smoAI"
              />
            </div>
          </div>
          <div className="mt-3">
            <label htmlFor="phone" className="w-100 mb-1 d-flex text-left">
              Phone number
            </label>
            <PhoneInput
              country={"in"}
              preferredCountries={["in"]}
              onChange={(phoneNumber) => {
                setPhoneNumber(phoneNumber);
              }}
              value={phoneNumber}
              inputClass="inputField"
              // buttonClass="countryPickerContainer"
              enableSearch={false}
              countryCodeEditable={false}
              disableDropdown
              autoFormat={false}
              specialLabel=""
            />
          </div>
          <div className="mt-3">
            <div className="form-group">
              <label htmlFor="email" className="w-100 mb-1 d-flex text-left">
                Quantity
              </label>
              <input
                type="number"
                className="form-control"
                id="quantity"
                placeholder="Enter email"
                onChange={(e) => {
                  setQuantity(Number(e.target.value));
                }}
                value={quantity}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-3 pb-4">
          <Button
            className="d-flex align-items-center btn btn-dark px-5"
            onClick={onNext}
            disabled={isGeneratingOtp}
          >
            {isGeneratingOtp ? "Generating OTP" : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
