import { addEvent } from "../api/api";

const useCaptureEvent = () => {
  const fireEvent = async (event_name: string, parameters: any) => {
    try {
      const date = new Date().toISOString();
      parameters.date = date;
      await addEvent({
        event: event_name,
        parameters,
      });
    } catch (e: any) {
      console.log(e);
      return;
    }
  };
  return {
    fireEvent,
  };
};

export default useCaptureEvent;
