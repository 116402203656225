import React from "react";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import styles from "./styles/OtpForm.module.css";
import closeIcon from "../assets/icons/close.svg";

interface OtpFormProps {
  otp: string;
  setOtp: (otp: string) => void;
  onGetQuote: () => void;
  onClose: () => void;
}

const OtpForm: React.FC<OtpFormProps> = ({
  otp,
  setOtp,
  onGetQuote,
  onClose,
}) => {
  return (
    <div
      className="position-fixed start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center "
      style={{ background: "rgba(0,0,0,0.5)" }}
    >
      <div className="bg-white rounded col-10 col-xl-3 h-25 position-relative d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h4 className="ps-3 pb-0 mb-0">Enter OTP</h4>
          <Button
            className="btn-dark bg-transparent border-0 text-dark"
            style={{ zIndex: "1000", bottom: 120, right: 0 }}
            onClick={onClose}
          >
            <img src={closeIcon} alt="closeIcon" />
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center px-5">
          <OtpInput
            inputStyle={`${styles.singleOtpInputField} border rounded text-dark h3`}
            containerStyle="my-4"
            numInputs={4}
            separator={<span className="mx-3"></span>}
            value={otp}
            onChange={(otp: string) => {
              setOtp(otp);
            }}
            isInputNum={true}
          />
        </div>
        <div className="d-flex justify-content-center pt-3 pb-4">
          <Button
            className="d-flex align-items-center btn btn-dark px-5"
            onClick={onGetQuote}
          >
            Get Quote
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
