import { createSlice } from "@reduxjs/toolkit";
import { StepType, UserConfiguration } from "../../types";

const initialState: {
  userConfiguration: Partial<UserConfiguration>;
  disabledFeatures: string[];
  disabledOptions: string[];
  currentStep: StepType | null;
  selectedOption: any;
  editingStep: StepType | null;
  editingStepIndex: number | undefined;
  addingStep: StepType | null;
  isAddingStep: boolean;
  editingOption: any;
} = {
  userConfiguration: {},
  disabledFeatures: [],
  disabledOptions: [],
  currentStep: null,
  selectedOption: {
    name: "",
    rules: [],
    thumbnail: "",
  },
  editingStep: null,
  editingStepIndex: undefined,
  addingStep: null,
  isAddingStep: false,
  editingOption: null,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    UPDATE_USER_CONFIGURATION(state, action) {
      state.userConfiguration = action.payload;
    },
    RESET_USER_CONFIGURATION(state) {
      state.userConfiguration = {};
    },
    SET_CURRENT_STEP(state, action) {
      state.currentStep = action.payload;
    },
    SET_EDITING_STEP(state, action) {
      state.editingStep = action.payload;
    },
    SET_EDITING_STEP_INDEX(state, action) {
      state.editingStepIndex = action.payload;
    },
    SET_ADDING_STEP(state, action) {
      state.addingStep = action.payload;
    },
    SET_EDITING_OPTION(state, action) {
      state.editingOption = action.payload;
    },
    SET_SELECTED_OPTION(state, action) {
      state.selectedOption = action.payload;
    },
    TOGGLE_ADDING_STEP(state) {
      state.isAddingStep = !state.isAddingStep;
    },
    DISABLE_FEATURES(state, action) {
      const newArr = [...state.disabledFeatures];
      action.payload.forEach((element: string) => {
        if (!newArr.includes(element)) {
          newArr.push(element);
        }
      });
      state.disabledFeatures = [...newArr];
    },
    ENABLE_FEATURES(state, action) {
      state.disabledFeatures = [...state.disabledFeatures].filter(
        (item) => !action.payload.includes(item)
      );
    },
  },
});

export const {
  UPDATE_USER_CONFIGURATION,
  RESET_USER_CONFIGURATION,
  SET_CURRENT_STEP,
  SET_ADDING_STEP,
  TOGGLE_ADDING_STEP,
  SET_EDITING_STEP,
  SET_EDITING_STEP_INDEX,
  SET_EDITING_OPTION,
  SET_SELECTED_OPTION,
  DISABLE_FEATURES,
  ENABLE_FEATURES,
} = configurationSlice.actions;
export default configurationSlice.reducer;
