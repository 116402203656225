import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
  // ENABLE_FEATURES,
  // DISABLE_FEATURES,
  UPDATE_USER_CONFIGURATION,
} from "../store/userConfiguration/configurationSlice";

const useUserConfiguration = () => {
  const dispatch = useAppDispatch();
  const userConfiguration: any = useAppSelector(
    (state) => state.configuration.userConfiguration
  );

  const productLookupId = useAppSelector((state) => state.product.product?.lookup_id)
  const stepsData = useAppSelector(
    (state) => state.product.configuratorRules?.steps
  );


  const updateUserConfiguration = (
    stepName: string,
    selectedOptionName: string | number
  ) => {
    const selectedStep = stepsData.find((step: any) => step.name.toLowerCase() === stepName.toLowerCase());
    const selectedOption = selectedStep.options.find(
      (option: any) => option.name === selectedOptionName
    );
    console.log(selectedOption, "selected Option");
    const tempUserConfiguration = {
      ...userConfiguration,
      [selectedStep.id
        ? selectedStep.id
        : stepName.toLowerCase().replaceAll(" ", "_")]: {
        name: selectedOptionName,
        value: selectedOption?.lookup_id || "",
      },
      ...(selectedOption?.enableExtraCustomisations?.executableSteps || [])
        .map((step: any) => ({
          [stepsData.find((stepData: any) => stepData.name === step.name).id
            ? stepsData.find((stepData: any) => stepData.name === step.name).id
            : stepsData
              .find((stepData: any) => stepData.name === step.name)
              .name.toLowerCase()
              .replaceAll(" ", "_")]: {
            name: step.option.name,
            value: step.option.lookup_id || "",
          },
        }))
        .reduce((acc: any, obj: any) => Object.assign(acc, obj), {}),
    };

    dispatch(UPDATE_USER_CONFIGURATION(tempUserConfiguration));
    const message = {
      event_name: "HELLOAR_OPTION_CLICKED",
      data: {
        configuration: {
          product_lookup_id: productLookupId ?? "",
          ...tempUserConfiguration,
        },

      },
    };
    if (window && window.parent) {
      window.parent.postMessage(JSON.stringify(message), "*");
    }
    console.log(message, "option message");
  };

  const updateUserConfigurationDropdown = (
    stepIndex: Number | undefined,
    selectedOption: any,
    selectedDropdownName: string
  ) => {
    const selectedStep = stepsData.find(
      (step: any, index: number) => index === stepIndex
    );
    let tempUserConfiguration;
    if (selectedStep.selector_type === "single") {
      tempUserConfiguration = {
        ...userConfiguration,
        [selectedStep.id
          ? selectedStep.id
          : selectedStep.name.toLowerCase().replaceAll(" ", "_")]:
          selectedOption,
      };
    } else {
      tempUserConfiguration = {
        ...userConfiguration,
        [selectedStep.id
          ? selectedStep.id
          : selectedStep.name.toLowerCase().replaceAll(" ", "_")]: {
          ...userConfiguration[
          selectedStep.id
            ? selectedStep.id
            : selectedStep.name.toLowerCase().replaceAll(" ", "_")
          ],
          [selectedDropdownName]: selectedOption,
        },
      };
    }
    dispatch(UPDATE_USER_CONFIGURATION(tempUserConfiguration));
    const message = {
      event_name: "HELLOAR_OPTION_CLICKED",
      data: {
        configuration: {
          product_lookup_id: productLookupId ?? "",
          ...tempUserConfiguration,
        },

      },
    };
    if (window && window.parent) {
      window.parent.postMessage(JSON.stringify(message), "*");
    }
    console.log(message, "option message");

  };

  const getSelectedOption = (stepName: string) => {
    const selectedStep = stepsData.find((step: any) => step.name === stepName);

    return userConfiguration[selectedStep.id ? selectedStep.id : stepName]
      ?.name;
  };

  const getSelectedOptionForCustomStep = (stepName: string) => {
    const selectedStep = stepsData.find((step: any) => {
      return step.name.toLowerCase() === stepName.toLowerCase()
    });

    if (selectedStep?.id) {
      return userConfiguration[selectedStep.id ? selectedStep.id : stepName]?.name;
    }
  };

  return {
    updateUserConfiguration,
    updateUserConfigurationDropdown,
    getSelectedOption,
    getSelectedOptionForCustomStep
  };
};

export default useUserConfiguration;
