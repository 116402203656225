import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";

interface AddOrEditDimensionOptionType {
  dimensionHandleChangeOption: (e: React.ChangeEvent<HTMLInputElement>) => void;
  editOptionCopy: any;
  editStepCopy: any;
  setEditOptionCopy: (editStepCopy: any) => void;
  setDimensionType: (dimensionType: string) => void;
  dimensionType: string | undefined;
  isAddingDimension: boolean;
  setIsAddingDimension: (isAddingDimenmsion: boolean) => void;
}

const AddOrEditDimensionOptionForm = ({
  dimensionHandleChangeOption,
  editOptionCopy,
  editStepCopy,
  setEditOptionCopy,
  setDimensionType,
  dimensionType,
  isAddingDimension,
  setIsAddingDimension,
}: AddOrEditDimensionOptionType) => {
  const DimensionTypeOptions = ["Length", "Width", "Depth"];

  const [dimensionDetails, setDimensionDetails] = useState<{
    name: string;
    value: number;
  }>({
    name: "",
    value: 0,
  });

  useEffect(() => {
    if (isAddingDimension) {
      setDimensionDetails({
        name: "",
        value: 0,
      });
    }
  }, [isAddingDimension]);
  useEffect(() => {
    if (editOptionCopy.name) {
      setDimensionType(editOptionCopy.name);
    } else {
      setDimensionType("Length");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStepCopy]);

  const handleDeleteDimensionOption = (optionIndex: number) => {
    const filteredOptions = editOptionCopy.options.filter(
      (option: any, index: number) => {
        return index !== optionIndex;
      }
    );
    setEditOptionCopy({ ...editOptionCopy, options: filteredOptions });
  };
  const handleChangeDimensionDetails = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDimensionDetails({
      ...dimensionDetails,
      [e.target.name]:
        e.target.name !== "value" ? e.target.value : parseInt(e.target.value),
    });
  };
  const handleAddDimension = () => {
    if (!dimensionDetails.name) {
      alert("Please enter a valid name for this dimension");
      return;
    }
    if (dimensionDetails.value < 1) {
      alert("Please enter a valid dimension value");
      return;
    }
    const tempDimensionOptions =
      editOptionCopy?.options?.length > 0
        ? [...editOptionCopy.options, dimensionDetails]
        : [dimensionDetails];
    setEditOptionCopy({ ...editOptionCopy, options: tempDimensionOptions });

    setIsAddingDimension(false);
  };
  return (
    <div>
      <Row className="mt-2 px-2 align-items-center mb-3">
        <Col xs={2} className="p-0 ">
          <label htmlFor="pid" className="m-0">
            Type :
          </label>
        </Col>
        <Col>
          <select
            name="dimensionCardSelection"
            id="dimensionCardSelection"
            className="p-1"
            onChange={(e) => {
              setDimensionType(e.target.value);
            }}
            disabled={editOptionCopy?.name.length > 0 ? true : false}
          >
            {DimensionTypeOptions.map((option: string, index: number) => {
              return (
                <option
                  key={index}
                  selected={
                    dimensionType && dimensionType === option ? true : false
                  }
                  value={option}
                >
                  {option}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="text-center m-0">
            {isAddingDimension ? `Adding Option` : "Options"}
          </h5>
          <button
            onClick={() => {
              if (isAddingDimension) {
                handleAddDimension();
              } else {
                setDimensionDetails({
                  name: "",
                  value: 0,
                });
                setIsAddingDimension(true);
              }
            }}
            className="btn btn-dark"
          >
            Add Option
          </button>
        </div>
        {!isAddingDimension ? (
          <Row className="mt-2 px-2 align-items-center mb-3">
            {editOptionCopy.options.map((option: any, index: number) => {
              return (
                <Col key={index} xs={4} className={`${index > 2 && "mt-3"}`}>
                  <div className="p-2 border d-flex justify-content-between align-items-center">
                    <div>{option.name}</div>
                    <FiTrash2
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleDeleteDimensionOption(index);
                      }}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        ) : (
          <div>
            <Row className="mt-2 px-2 align-items-center mb-3">
              <Col xs={3} className="p-0 ">
                <label htmlFor="name" className="m-0">
                  Name * :
                </label>
              </Col>
              <Col>
                <input
                  type="text"
                  className="pl-1 w-100"
                  id="name"
                  name="name"
                  onChange={(e) => {
                    handleChangeDimensionDetails(e);
                  }}
                  value={dimensionDetails.name}
                />
              </Col>
            </Row>
            <Row className="mt-2 px-2 align-items-center mb-3">
              <Col xs={3} className="p-0 ">
                <label htmlFor="name" className="m-0">
                  Value * :
                </label>
              </Col>
              <Col>
                <input
                  type="number"
                  className="pl-1 w-100"
                  id="value"
                  name="value"
                  onChange={(e) => {
                    handleChangeDimensionDetails(e);
                  }}
                  value={dimensionDetails.value}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddOrEditDimensionOptionForm;
