import React from "react";
import { Col, Row } from "react-bootstrap";
import { v4 } from "uuid";
import { StepType } from "../types";
import DropDown from "./DropDown";
import RenderThumbnails from "./RenderThumbnails";

const RenderOptions = ({
  cardOptions,
  step,
  stepIndex,
  sessionId,
  isEditMode,
  selectorType,
}: {
  cardOptions: any;
  step: StepType;
  stepIndex?: number;
  sessionId?: string;
  isEditMode?: boolean;
  selectorType?: "single" | "multiple" | undefined;
}) => {
  const dropDowns = cardOptions.filter((option: any) => option.dropDown);
  let renderThumbnails = cardOptions.filter((option: any) => !option.dropDown);

  if (step.type === "part_selector" && !step.enableNoneOption) {
    renderThumbnails = renderThumbnails.filter(
      (option: any) => option.name !== "None"
    );
  } else if (step.type !== "part_selector") {
    renderThumbnails = renderThumbnails.filter(
      (option: any) => option.name !== "None"
    );
  }

  return (
    <Col className="px-2">
      <Row>
        {dropDowns.length > 0 &&
          dropDowns
            .filter((option: any) => option.options.length > 0)
            .map((option: any, index: number) => {
              const { name, options } = option;
              return (
                <DropDown
                  name={name}
                  options={options}
                  key={name}
                  dropDownIndex={index}
                  selectorType={selectorType}
                  stepIndex={stepIndex}
                  parent={option}
                />
              );
            })}
      </Row>
      <Row>
        {renderThumbnails.length > 0 &&
          renderThumbnails.map((option: any, index: number) => {
            return (
              <RenderThumbnails
                key={option.name + v4()}
                option={option}
                step={step}
                index={index}
                sessionId={sessionId}
                isEditMode={isEditMode}
              />
            );
          })}
      </Row>
    </Col>
  );
};

export default RenderOptions;
