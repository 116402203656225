import React from "react";
import { Card } from "react-bootstrap";
import RenderOptions from "./RenderOptions";

interface VariantsCardType {
  step: any;
  sessionId:string;
  isEditMode?:boolean;
}

const VariantsCard: React.FC<VariantsCardType> = ({ step,sessionId ,isEditMode}) => {
  return (
    <div>
      <div className="d-flex mb-4" id="sidebarCard">
        <Card
          style={{
            width: "100%",
            border: "none",
          }}
        >
          <Card.Body className="text-start py-1 px-0 mx-0">
            <Card.Title className="mb-4 w-100 d-flex justify-content-between align-items-center">
              {step.name}
            </Card.Title>
            <RenderOptions cardOptions={step.options} isEditMode={isEditMode} step={step} sessionId={sessionId} />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default VariantsCard;
