import axios from "axios";
import Cookies from "js-cookie";
import { getMaterials, getParts } from "../api/api";

export const userConstants = {
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",
};

export const getApplicationRoles = async () => {
  const userId = getUserId();
  const whereQuery = {
    account: userId,
    application: process.env.REACT_APP_APPLICATION_ID,
  };

  return await axios.get(
    process.env.REACT_APP_HELLOAR_API + "application_roles",
    {
      params: {
        where: whereQuery,
      },
    }
  );
};

export const isUserLoggedIn = () => {
  let authString: string | null | undefined;

  if (process.env.REACT_APP_HELLOAR_STAGING === "true") {
    authString = localStorage.getItem("parent_auth") ?? Cookies.get("dev_auth");
    if (!authString) {
      authString = localStorage.getItem("auth");
      if (authString) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    authString = localStorage.getItem("parent_auth") ?? Cookies.get("auth");
    if (!authString) {
      return false;
    } else {
      return true;
    }
  }
};

export const isEnvProd = () => {
  if (process.env.REACT_APP_HELLOAR_STAGING === "true") {
    return false;
  } else return true;
};

export const getAuth = () => {
  let authString: string | null | undefined;

  if (process.env.REACT_APP_HELLOAR_STAGING === "true") {
      authString = localStorage.getItem("parent_auth") ?? Cookies.get("dev_auth");
    if (!authString) {
      authString = localStorage.getItem("auth");
    }
  } else {
    authString = localStorage.getItem("parent_auth") ?? Cookies.get("auth");
  }
  return authString;
};

export const getUserId = () => {
  let userId: string | null | undefined;

  if (process.env.REACT_APP_HELLOAR_STAGING === "true") {
    userId = localStorage.getItem("parent_userId") ?? Cookies.get("dev_userId");
    if (!userId) {
      const user = localStorage.getItem("user");
      if (user) {
        userId = JSON.parse(user).account_id;
      }
    }
  } else {
    userId = localStorage.getItem("parent_userId") ?? Cookies.get("userId");
  }
  return userId;
};

export const getPartsAndMaterials = async (applicationId: string) => {
  try {
    const parts = await getParts(applicationId);
    const materials = await getMaterials(applicationId);
    return { parts, materials };
  } catch (error) {
    throw new Error(
      `unable to fetch parts and materials due to error: ${JSON.stringify(
        error
      )}`
    );
  }
};
