import { useState, useEffect } from "react";

const useIsPortrait = () => {
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width < height) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    };
    onResize();

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isPortrait;
};

export default useIsPortrait;
