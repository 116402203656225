import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../hooks/hooks";
import { isUserLoggedIn, userConstants } from "../utils/utils";

export default function Login() {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState<{ requestId: string } | undefined>();
  const [otp, setOtp] = useState("");
  const [generatingOtp, setGeneratingOtp] = useState(false);
  const [verifyingOtp, setVerifyingOtp] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn()) {
      navigate(`/customize-product/${id}`);
    }
  }, [id, navigate]);

  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;

  const sendOtp = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (
      !email.match(/^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)
    ) {
      alert("Invalid email");
      return;
    }
    setGeneratingOtp(true);
    axios
      .post(baseUrl + "/auth/send_otp", {
        email: email,
        app_source: "web",
      })
      .then((res) => {
        setResponse({ requestId: res.data.request_id });
        setGeneratingOtp(false);
      })
      .catch((err) => {
        toast.error("User not found");
        setGeneratingOtp(false);
      });
  };

  const verifyOtp = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!response?.requestId) {
      return;
    }
    setVerifyingOtp(true);
    axios
      .post(baseUrl + "/auth/verify_otp", {
        request_id: response.requestId,
        otp: parseInt(otp),
      })
      .then(async (res) => {
        localStorage.setItem("auth", res.data.access_token);
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...res.data,
            token: res.data.access_token,
            email,
          })
        );
        dispatch({
          type: userConstants.SET_USER,
          payload: { ...res.data, token: res.data.access_token, email },
        });
        navigate(`/customize-product/${id}`);

        setVerifyingOtp(false);

        setResponse(undefined);
        setOtp("");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Invalid OTP");
        setVerifyingOtp(false);
      });
  };

  return (
    <div className=" d-flex align-items-center flex-column h-100">
      <div className="d-flex flex-column w-100 justify-content-center align-items-center">
        {response?.requestId ? (
          <form
            onSubmit={verifyOtp}
            style={{ height: "max-content" }}
            className="p-4 d-flex flex-column align-items-center justify-content-between"
          >
            {/* <div className="flex justify-center py-4 mb-6 flex-grow-0">
              <img src={logo} className="h-16" alt="" />
            </div> */}
            <h4 className="text-2xl font-bold mb-4 text-center">
              Enter the OTP sent to <br /> {email}
            </h4>

            <OtpInput
              inputStyle="h4"
              containerStyle="mb-3 d-flex align-items-center justify-content-center"
              numInputs={4}
              separator={<span className="mx-1">-</span>}
              value={otp}
              onChange={(otp: string) => setOtp(otp)}
            />
            {/* <h3 onClick={sendOtp} className="h5">
              {generatingOtp ? "Generating OTP..." : "Resend OTP"}
            </h3> */}
            <button onClick={verifyOtp} className="btn btn-dark">
              {verifyingOtp ? "Verifying OTP..." : "Confirm"}
            </button>
            <h6
              onClick={() => setResponse(undefined)}
              className="mt-4 text-decoration-underline"
              style={{ cursor: "pointer" }}
            >
              Use different email address
            </h6>
          </form>
        ) : (
          <form
            onSubmit={sendOtp}
            style={{ height: "max-content" }}
            className=""
          >
            {/* <div className="flex justify-center py-4 mb-6 flex-grow-0">
              <img src={logo} className="h-16" alt="" />
            </div> */}
            <h3 className="text-xl my-4">Email Address</h3>
            <div className="d-flex justify-content-center align-items-center">
              <input
                className="py-1"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                // onClick={sendOtp}
                disabled={generatingOtp}
                className="btn btn-dark"
              >
                {generatingOtp ? "Generating OTP..." : "Next"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
