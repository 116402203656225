import React, { useState } from "react";
import { postOrder, sendOrderOtp, verifyOrderOtp } from "../api/orders";
import CustomerDetails from "../components/CustomerDetailsForm";
import OtpForm from "../components/OtpForm";
import PopUpNotification from "../components/PopUpNotification";
import { useAppSelector } from "../hooks/hooks";
import { Order } from "../types";

interface OrderFormProps {
  onClose: () => void;
}

const OrderForm: React.FC<OrderFormProps> = ({ onClose }) => {
  const [showCustomerDetailsForm, setShowCustomerDetailsForm] = useState(true);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpResponseData, setOtpResponseData] = useState<any>(undefined);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState<null | any>(null);
  const [otp, setOtp] = useState("");
  const [quantity, setQuantity] = useState<number>(1);
  const [isGeneratingOtp, setIsGeneratingOtp] = useState(false);
  const [popupState, setPopupState] = useState<
    undefined | "Success" | "Failed"
  >(undefined);
  const popupSuccessMessage = "Quotation requested successfully";
  const popupFailureMessage = "Quotation request failed :(";

  const onPopupClose = () => {
    if (popupState === "Success") {
      onClose();
    }
    setPopupState(undefined);
  };

  const onGetQuote = async () => {
    try {
      await verifyOrderOtp(otpResponseData.request_id, otp);
      setPopupState("Success");
      // notify("Quotation request successful");
      // onClose();
    } catch (error) {
      // alert(`Otp verification failed`);
      setPopupState("Failed");
      console.error(error);
    }
  };
  const productData = useAppSelector((state) => state.product.product);
  const userConfigData = useAppSelector(
    (state) => state.configuration.userConfiguration
  );

  const createAndSendOtp = async (order: Order) => {
    try {
      const orderId = await postOrder(order);
      return orderId;
    } catch (error) {
      alert("Unable to create order");
    }
  };

  const onNext = async () => {
    if (!name) {
      alert("Please enter a valid name");
      return;
    }
    if (
      !email &&
      !email.match(/^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)
    ) {
      alert("Please enter a valid email id");
      return;
    }
    if (!address) {
      alert("Please enter a valid city name");
      return;
    }
    if (
      !phoneNumber &&
      !phoneNumber.match(/([+]?\d[.91][.-\s]?)?(\d{3}?){2}\d{4}/)
    ) {
      alert("Please enter a valid phone number");
      return;
    }

    if (productData?._id) {
      const cartItem = {
        product: productData?._id,
        bom: {},
        configuration: { ...userConfigData },
        thumbnail: "",
        cost: 0,
        quantity: quantity,
      };
      const customer = {
        name: name,
        email: email,
        address: address,
        phone_number: `+${phoneNumber}`,
      };
      const order: Order = {
        cart_items: [cartItem],
        customer: customer,
        viewer: process.env.REACT_APP_HELLOAR_VIEWER_ID ?? "",
        status: {
          status: 1,
          description: "",
        },
      };
      try {
        setIsGeneratingOtp(true);
        const orderId = await createAndSendOtp(order);
        const otpResponse = await sendOrderOtp(`+${phoneNumber}`, orderId);
        setOtpResponseData(otpResponse);
        setShowCustomerDetailsForm(false);
        setShowOtpForm(true);
        setIsGeneratingOtp(false);
      } catch (error) {
        console.error(error);
        setIsGeneratingOtp(false);
        alert(`Unable to create order due to error: ${JSON.stringify(error)}`);
      }
    }
  };

  return (
    <div
      className="position-fixed start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center"
      style={{ backdropFilter: "blur(3px)" }}
    >
      <div className="bg-white rounded px-5 col-10 col-lg-3 position-relative">
        {showCustomerDetailsForm && (
          <CustomerDetails
            onNext={onNext}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
            name={name}
            setName={setName}
            address={address}
            setAddress={setAddress}
            quantity={quantity}
            setQuantity={setQuantity}
            onClose={onClose}
            isGeneratingOtp={isGeneratingOtp}
          />
        )}
        {showOtpForm && (
          <OtpForm
            setOtp={setOtp}
            otp={otp}
            onGetQuote={onGetQuote}
            onClose={onClose}
          />
        )}
      </div>
      {popupState !== undefined && (
        <PopUpNotification
          message={
            popupState === "Success" ? popupSuccessMessage : popupFailureMessage
          }
          onClose={onPopupClose}
        />
      )}
    </div>
  );
};

export default OrderForm;
