import React, { useEffect, useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { useAppSelector } from "../hooks/hooks";
import useIsPortrait from "../hooks/useIsPortrait";
import { HelloARProduct } from "../types";
// import { getBom } from "../utils/headerUtils";
import { isUserLoggedIn } from "../utils/utils";
import useCaptureEvent from "../hooks/useCaptureEvents";
import OrderForm from "../Layouts/OrderForm";
interface CTA {
  ctaText?: string;
  ctaOption?: boolean;
  iconUrl?: string;
  primaryColour?: string;
  showHelloArIcon?: boolean;
}

const Header = ({
  productViewerRef,
  product,
  enableHeader,
  sessionId,
  isEditMode,
}: {
  productViewerRef: any;
  product: HelloARProduct;
  enableHeader: boolean;
  sessionId: string;
  isEditMode?: boolean;
}) => {
  const userConfiguration = useAppSelector(
    (state) => state.configuration.userConfiguration
  );

  // const ctaType = "get_quote";
  // const [bom, setBom] = useState<
  //   { name: string; quantity: number; price: number }[] | undefined
  // >(undefined);
  const [price, setPrice] = useState(0);
  const [showCustomerDetailsForm, setShowCustomerDetailsForm] = useState(false);
  const [ctaValue, setCtaValue] = useState<CTA>({});
  const isPortrait = useIsPortrait();
  const { fireEvent } = useCaptureEvent();
  const applicationConfiguration = useAppSelector(
    (state) => state.product.applicationConfiguration
  );
  useEffect(() => {
    const getButtonText = async () => {
      if (applicationConfiguration) {
        applicationConfiguration.forEach((value: any) => {
          if (value.key === "button_text") {
            const cta = JSON.parse(value.value);
            setCtaValue(cta);
          }
        });
      }
    };

    getButtonText();
    // if (product._id === "633fee8747eae2db2678fa72") {
    //   // const { componentArray, totalPrice } = getBom(userConfiguration);
    //   // setBom(componentArray);
    //   const { totalPrice } = getBom(userConfiguration);
    //   getButtonText();
    //   setPrice(Number(totalPrice));
    // }
  }, [
    product._id,
    userConfiguration,
    product.application,
    applicationConfiguration,
  ]);

  // const resetCamera = async () => {
  //   return new Promise(async (resolve, reject) => {
  //     if (product.rules?.json) {
  //       console.log(product.rules.json);
  //       const rules: any[] = JSON.parse(product.rules.json).rules;
  //       if (rules.length > 0) {
  //         const cameraResetRule = rules.find((rule) => {
  //           return rule.actions?.find(
  //             (action: any) => action.type === "MoveCamera"
  //           );
  //         });
  //         productViewerRef.current?.viewer?.runCustomRule(
  //           cameraResetRule,
  //           () => resolve(true),
  //           () => reject(false)
  //         );
  //       }
  //     } else {
  //       return reject("camera reset rule not found");
  //     }
  //   });
  // };

  const addProductToOrder = async () => {
    // try {
    //   await resetCamera();
    // } catch (error) {
    //   console.error(error);
    // }
    if (Object.keys(userConfiguration).length > 0) {
      const thumbnailUrl = await productViewerRef.current?.viewer?.saveAsImage(
        "atmosphere/thumbnails/" + uuid()
      );

      const message = {
        event_name: "HELLOAR_CTA_CLICKED",
        data: {
          configuration: {
            product_lookup_id: product.lookup_id ?? "",
            ...userConfiguration,
          },
          total_cost: price,
          thumbnail: thumbnailUrl,
        },
      };
      console.log(message.data.configuration, "configuration");
      console.log(message, "message");
      const parameters = {
        total_cost: price,
        product_lookup_id: product.lookup_id,
        product_id: product._id,
        session_id: sessionId,
        user_type: isEditMode ? "Admin" : "User",
      };
      await fireEvent("CTA_BUTTON", parameters);
      if (window && window.parent) {
        window.parent.postMessage(JSON.stringify(message), "*");
      }
    } else {
      alert("Please choose atleast 1 configuration options to add product");
      return;
    }
  };

  const handleGetQuote = () => {
    if (Object.keys(userConfiguration).length < 1) {
      alert("Choose atleast 1 configuration");
      return;
    }
    setShowCustomerDetailsForm(true);
  };

  return (
    <div
      className="position-absolute px-3 px-md-5 pt-4 d-flex align-items-center"
      style={{ zIndex: 101, right: isPortrait ? "0" : "25rem" }}
    >
      {!isUserLoggedIn() && (
        <h5 className="me-5 mb-0">
          {!isNaN(price) && price > 0 && `Price: ${price} INR`}
        </h5>
      )}

      {(ctaValue.ctaOption || enableHeader) && (
        <button
          style={{
            backgroundColor:
              ctaValue.primaryColour && ctaValue.primaryColour.length > 0
                ? ctaValue.primaryColour
                : "black",
          }}
          className="d-flex align-items-center btn btn-dark"
          onClick={() => {
            if (ctaValue.ctaText?.toLowerCase() === "get quote") {
              handleGetQuote();
            } else {
              addProductToOrder();
            }
          }}
        >
          {ctaValue.iconUrl && ctaValue.iconUrl.length > 0 ? (
            <img
              width={"25px"}
              height={"25px"}
              style={{
                marginRight: "10px",
                backgroundColor: "transparent",
                objectFit: "contain",
              }}
              alt=""
              src={ctaValue.iconUrl as string}
            />
          ) : (
            <FaShoppingCart className="me-2" width={"100px"} height={"100px"} />
          )}
          <h5 className="m-0">
            {ctaValue.ctaText && ctaValue?.ctaText?.length > 0
              ? ctaValue.ctaText
              : "Add To Cart"}
          </h5>
        </button>
      )}

      {showCustomerDetailsForm && (
        <div className="position-relative">
          <OrderForm
            onClose={() => {
              setShowCustomerDetailsForm(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
