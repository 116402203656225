import { useState, useEffect } from "react";

const useDidWindowResize = () => {
  const [resizeVal, setResizeVal] = useState<number>(0);

  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width < height) {
        setResizeVal(height);
      } else {
        setResizeVal(width);
      }
    };
    onResize();

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return resizeVal;
};

export default useDidWindowResize;
