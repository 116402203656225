import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const removeToken = (token: any) => {
  if (token) {
    var newToken = token.includes("Token") ? token.split("Token ")[1] : token;
    return newToken;
  }
};

function AttemptLogin() {
  const [productId, setProductId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const onMessageReceived = (event: { data: string }) => {
      if (event && event.data) {
        let data = null;
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          data = event.data;
        }

        if (data) {
          if (data && data.event_name === "ATTEMPT_LOGIN") {
            localStorage.setItem("parent_auth", removeToken(data.data.auth_token)
            );
            localStorage.setItem("parent_userId", data.data.account_id);
            localStorage.setItem("parent_appId", data.data.application_id);
            setProductId(data.data.product_id);
          }
        }
      }
    };
    window.addEventListener("message", onMessageReceived);

    if(productId) {
        navigate(`/customize-product/${productId}`);
    }

    return () => {
      window.removeEventListener("message", onMessageReceived);
    };
  }, [navigate, productId]);

  if (productId) {
    return (
        <div id="emptyDiv">
        </div>
    );
  } else {
    return <div id="emptyDiv">loading...</div>;
  }
}

export default AttemptLogin;
