import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import AddOrEditPartMaterialOptionForm from "./AddOrEditPartMaterialOptionForm";
import AddOrEditDimensionOptionForm from "./AddOrEditDimensionOptionForm";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import {
  SET_EDITING_OPTION,
  SET_EDITING_STEP,
  TOGGLE_ADDING_STEP,
  UPDATE_USER_CONFIGURATION,
} from "../store/userConfiguration/configurationSlice";
// import { FiEdit, FiTrash2 } from "react-icons/fi";
// import editIcon from "../assets/icons/editIcon.svg";
import deleteIcon from "../assets/icons/deleteIcon.svg";
// import { GrFormClose } from "react-icons/gr";
import { patchProductConfiguratorRules } from "../api/api";
import { useParams } from "react-router-dom";
import { Option, StepType } from "../types";
import { SET_CONFIGURATOR_RULES } from "../store/product/productSlice";
import { isUserLoggedIn } from "../utils/utils";
// import { v4 as uuidv4 } from "uuid";
import { IoArrowBack } from "react-icons/io5";
import useIsPortrait from "../hooks/useIsPortrait";
import useUserConfiguration from "../utils/updateUserConfiguration";
import useCaptureEvent from "../hooks/useCaptureEvents";

const ModalContainer = ({ isEditMode }: { isEditMode?: boolean }) => {
  const dispatch = useAppDispatch();
  const { fireEvent } = useCaptureEvent();

  const editingStep = useAppSelector(
    (state) => state.configuration.editingStep
  );

  const editingOption = useAppSelector(
    (state) => state.configuration.editingOption
  );
  const productConfiguratorRules = useAppSelector(
    (state) => state.product.configuratorRules
  );

  const editingStepIndex = useAppSelector(
    (state) => state.configuration.editingStepIndex
  );
  const isAddingStep = useAppSelector(
    (state) => state.configuration.isAddingStep
  );

  const userConfig = useAppSelector(
    (state) => state.configuration.userConfiguration
  );

  let userConfigNew: any = userConfig;

  const materials = useAppSelector((state) => state.product.productMaterials);
  const parts = useAppSelector((state) => state.product.productParts);

  const { id } = useParams();
  const replaceSpaces = (name: string) => {
    return name.replaceAll(" ", "_");
  };

  const allStepTypes = [
    {
      name: "Part Selector",
      value: "part_selector",
    },
    {
      name: "Material Selector",
      value: "material_selector",
    },
    {
      name: "Dimension Selector",
      value: "dimension_selector",
    },
    {
      name: "Hide/Show Part",
      value: "hide_show_part_selector",
    },
    {
      name: "Custom Step",
      value: "custom_step",
    },
  ];
  const allDimensionSelectorTypes = [
    {
      name: "Single",
      value: "single",
    },
    {
      name: "Multiple",
      value: "multiple",
    },
  ];

  interface SingleDimensionObjectType {
    name: string;
    value: number | undefined;
  }
  interface DimensionObjectType {
    Length?: SingleDimensionObjectType;
    Width?: SingleDimensionObjectType;
    Depth?: SingleDimensionObjectType;
    Dimensions?: SingleDimensionObjectType;
  }

  const [showModal, setShowModal] = useState(false);
  const [editStepCopy, setEditStepCopy] = useState<any | null>(editingStep);
  const [editOptionCopy, setEditOptionCopy] = useState<any>(editingOption);
  const [editOptionIndex, setEditOptionIndex] = useState<number | undefined>(
    undefined
  );
  const [customOption, setCustomOption] = useState({
    name: "",
    thumbnail: "",
    rules: [],
  });
  const [dimensionType, setDimensionType] = useState<string | undefined>(
    undefined
  );
  const [isAddingDimension, setIsAddingDimension] = useState(false);
  const [productMaterials, setProductMaterials] = useState<any[] | undefined>(
    undefined
  );
  const [dimensionObject, setDimensionObject] = useState<
    DimensionObjectType | any
  >({
    Length: { name: "", value: undefined },
    Width: { name: "", value: undefined },
    Depth: { name: "", value: undefined },
    Dimensions: { name: "", value: undefined },
  });
  const [productParts, setProductParts] = useState<any[] | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAddingOption, setSelectedAddingOption] = useState("");
  const [partNodeNames, setPartNodeNames] = useState<string>("");
  const [enableExtraCustomisations, setEnableExtraCustomisations] =
    useState<any>(false);

  const [extraCustomisationOption, setExtraCustomisationOption] =
    useState<any>(null);

  const stepIdRef = useRef<any>();
  useEffect(() => {
    if (isUserLoggedIn() && materials) {
      const mappedMaterialsData = materials.map((material: any) => {
        return {
          name: material.name,
          node: material.name,
          type: "material_selector",
          material_id: material._id,
          options: [...material.textures].map((texture: any) => {
            return {
              name: texture.name,
              thumbnail: texture.thumbnail,
              rules: [{ textureUrl: texture.base }],
              lookup_id: texture.lookup_id ? texture.lookup_id : "",
            };
          }),
        };
      });
      setProductMaterials(mappedMaterialsData);
    }
  }, [materials]);

  useEffect(() => {
    if (isUserLoggedIn() && parts) {
      const mappedPartsData = parts.map((m: any) => {
        return {
          name: m.name,
          type: "part_selector",
          node: [],
          enableNoneOption: false,
          part_id: m._id,
          defaultOption: m.defaultOption,
          options: [...m.options].map((part: any) => {
            return {
              name: part.name,
              thumbnail: part.thumbnail,
              rules: [{ glb: part.glb }],
              lookup_id: part.lookup_id ? part.lookup_id : "",
            };
          }),
        };
      });
      setProductParts(mappedPartsData);
    }
  }, [parts]);

  useEffect(() => {
    editingStep !== null || isAddingStep
      ? setShowModal(true)
      : setShowModal(false);
    setEditStepCopy(editingStep);
  }, [editingStep, isAddingStep]);

  useEffect(() => {
    setEditOptionCopy(editingOption);
  }, [editingOption]);

  useEffect(() => {
    if (isAddingStep) {
      setEditStepCopy({
        name: "",
        type: "",
        options: [],
      });
    }
  }, [editingStep, isAddingStep]);

  const handleCloseModal = () => {
    if (editingOption) {
      handleGoBack();
      setEnableExtraCustomisations(false);
    } else {
      setShowModal(false);
      if (isAddingStep) {
        setEditStepCopy(null);
        setEnableExtraCustomisations(false);
        dispatch(TOGGLE_ADDING_STEP());
      } else {
        setEnableExtraCustomisations(false);
        dispatch(SET_EDITING_OPTION(null));
        dispatch(SET_EDITING_STEP(null));
        setEditOptionCopy(null);
        setEditOptionIndex(undefined);
      }
    }
  };

  const onCustomOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "option_name" && e.target.value) {
      setCustomOption((prev) => ({
        ...prev,
        name: e.target.value,
      }));
    }
    if (e.target.name === "option_thumbnail") {
      setCustomOption((prev) => ({
        ...prev,
        thumbnail: e.target.value,
      }));
    }
  };

  const onAddCustomOption = () => {
    const regex = /^https:\/\/.*/i;
    console.log(regex.test(customOption.thumbnail));

    if (!customOption.name) {
      alert("Please enter a valid Name");
      return;
    }

    if (!customOption.thumbnail) {
      alert("Please enter a valid Thumbnail Url");
      return;
    } else if (!regex.test(customOption.thumbnail)) {
      alert("Please enter a valid Thumbnail Url");
      return;
    }
    const tempOptions = [...editStepCopy.options];
    const tempEditStepCopy = {
      ...editStepCopy,
      options: [...tempOptions, customOption],
    };
    setEditStepCopy(tempEditStepCopy);
    setCustomOption({
      name: "",
      thumbnail: "",
      rules: [],
    });
  };

  const handleGoBack = () => {
    if (isAddingDimension) {
      setIsAddingDimension(false);
    } else {
      dispatch(SET_EDITING_OPTION(null));
      setEditOptionIndex(undefined);
      setEditOptionCopy(null);
      setDimensionType(undefined);
    }
  };
  const checkIfDimensionExists = (dimensionOptions: any, dimension: any) => {
    if (
      dimensionOptions.find((d: any) => {
        return dimension.name === d.name;
      })
    ) {
      alert("This dimension name already exists");
      return true;
    } else if (
      dimensionOptions.find((d: any) => {
        return dimension.value === d.value;
      })
    ) {
      alert("This dimension value already exists");
      return true;
    } else {
      return false;
    }
  };
  const handleAddNewDimension = (name: string) => {
    const tempSingleDimensionObject: any = { ...dimensionObject }[name];
    if (!tempSingleDimensionObject.name) {
      alert(`Enter a valid name for ${name}`);
      return;
    }
    if (
      !tempSingleDimensionObject.value ||
      tempSingleDimensionObject.value < 1
    ) {
      alert(`Enter a valid value for ${name}`);
      return;
    }
    let tempEditedStepCopy = { ...editStepCopy };
    let tempStepOptions = tempEditedStepCopy.options;
    const dimensionIndex = tempStepOptions.indexOf(
      tempStepOptions.find((op: any) => op.name === name)
    );
    const editingDimension = tempStepOptions[dimensionIndex];
    const tempDimensionOptions: any = [
      ...tempStepOptions[dimensionIndex].options,
    ];
    if (
      checkIfDimensionExists(
        editStepCopy.options[dimensionIndex].options,
        dimensionObject[name]
      )
    ) {
      return;
    }
    let tempDimensionObject: any = { ...dimensionObject };
    tempDimensionOptions.push(tempDimensionObject[name]);
    const editedDimension = {
      ...editingDimension,
      options: tempDimensionOptions,
    };
    tempStepOptions = tempStepOptions.map((option: any) => {
      if (option.name === name) {
        return editedDimension;
      } else {
        return option;
      }
    });
    tempEditedStepCopy = { ...tempEditedStepCopy, options: tempStepOptions };
    tempDimensionObject[name] = {
      name: "",
      value: undefined,
    };
    setEditStepCopy(tempEditedStepCopy);
    setDimensionObject(tempDimensionObject);
  };

  const handleChangeNewDimensionDetails = (e: any) => {
    const targetName: "Length" | "Width" | "Depth" | "Dimensions" =
      e.target.name.split("-")[0];
    if (e.target.name.includes("nameInput")) {
      const tempDimensionObject = {
        ...dimensionObject,
        [targetName]: {
          name: e.target.value,
          value: dimensionObject[targetName].value,
        },
      };
      setDimensionObject(tempDimensionObject);
    } else {
      const tempDimensionObject = {
        ...dimensionObject,
        [targetName]: {
          name: dimensionObject[targetName].name,
          value: e.target.value,
        },
      };
      setDimensionObject(tempDimensionObject);
    }
  };

  const handleDeleteNewDimensionOption = (name: string, optionIdx: number) => {
    let tempEditStepCopy = { ...editStepCopy };
    let tempEditOptionCopy = {
      ...tempEditStepCopy.options.find((option: any) => option.name === name),
    };
    const dimensionOptions = [...tempEditOptionCopy.options];
    const filteredDimensionOptions = dimensionOptions.filter(
      (option: any, index: number) => index !== optionIdx
    );
    tempEditOptionCopy = {
      ...tempEditOptionCopy,
      options: filteredDimensionOptions,
    };
    const editedStepOptions = tempEditStepCopy.options.map((option: any) => {
      if (option.name === name) {
        return tempEditOptionCopy;
      } else {
        return option;
      }
    });
    tempEditStepCopy = { ...tempEditStepCopy, options: editedStepOptions };
    setEditStepCopy(tempEditStepCopy);
  };

  const checkConditions = () => {
    if (!editOptionCopy.name) {
      alert("Please enter a valid option name");
      return false;
    }

    if (
      editStepCopy?.type === "part_selector" &&
      !editOptionCopy?.rules[0].pid
    ) {
      alert("Please enter a valid Product ID");
      return false;
    }

    return true;
  };

  const handleDeleteStep = async (stepIndex: number) => {
    const message = `Click on 'OK' to delete ${editingStep?.name}`;
    if (window.confirm(message)) {
      const tempConfiguratorRules: any = { ...productConfiguratorRules };
      const tempSteps: StepType[] = [...tempConfiguratorRules.steps];
      const filteredSteps = tempSteps.filter(
        (step: StepType, index: number) => {
          return index !== stepIndex;
        }
      );
      tempConfiguratorRules.steps = filteredSteps;
      if (id && editingStep) {
        try {
          await patchProductConfiguratorRules(id, tempConfiguratorRules);
          dispatch(SET_CONFIGURATOR_RULES(tempConfiguratorRules));
          dispatch(SET_EDITING_STEP(null));
          const parameters = {
            product_id: id,
            customization_name: editingStep.name,
            customization_id: editingStep.id,
            customization_type: editingStep.type,
            user_type: isEditMode ? "Admin" : "User",
          };
          await fireEvent("DELETE_CUSTOMIZATION", parameters);
          setShowModal(false);
        } catch (error) {
          console.error(error);
          alert(`Unable to delete step due to error: ${JSON.stringify(error)}`);
        }
      }
    }
  };

  const patchRules = async (editedStep: any) => {
    setIsLoading(true);
    const tempConfiguratorRules: any = { ...productConfiguratorRules };
    const tempSteps = isAddingStep
      ? [editedStep, ...tempConfiguratorRules.steps]
      : [...tempConfiguratorRules.steps];
    if (editingStepIndex !== undefined && editingStepIndex >= 0) {
      tempSteps[editingStepIndex] = editedStep;
    }
    tempConfiguratorRules.steps = tempSteps;
    if (id) {
      try {
        await patchProductConfiguratorRules(id, tempConfiguratorRules);
        dispatch(SET_CONFIGURATOR_RULES(tempConfiguratorRules));
        if (isAddingStep) {
          dispatch(TOGGLE_ADDING_STEP());
        }
        if (isAddingDimension) {
          setIsAddingDimension(false);
        }
        setIsLoading(false);
        setShowModal(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        alert(`Unable to save changes due to error: ${JSON.stringify(error)}`);
      }
    }
  };

  const checkIfStepNameExists = (editedStep: StepType) => {
    if (
      productConfiguratorRules &&
      productConfiguratorRules.steps.find(
        (step: StepType) =>
          step.name.toLowerCase() === editedStep.name.toLowerCase()
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const stepConfirmationMessage = `Do you want to save ${editStepCopy.name} with current changes?`;
      const optionConfirmationMessage = editOptionCopy
        ? `Do you want to save ${editOptionCopy.name} with current changes?`
        : "";
      if (editStepCopy.custom_json) {
        try {
          JSON.parse(editStepCopy.custom_json);
        } catch (error) {
          throw new Error("Entered custom Json is invalid");
        }
      }
      if (!editStepCopy.name) {
        throw new Error("Please enter a valid name");
      }

      if (!editStepCopy?.type) {
        throw new Error("Please select a valid interaction type");
      }
      if (isAddingStep && !editStepCopy?.id) {
        throw new Error("Please enter a valid interaction id");
      }
      if (
        editStepCopy?.type === "material_selector" &&
        editStepCopy?.node?.length === 0 &&
        !isAddingStep
      ) {
        if (editOptionCopy !== null && !editOptionCopy?.name) {
          throw new Error("Please enter option name");
        } else {
          throw new Error("Please enter a atleast one node name");
        }
      }
      if (
        !isAddingStep &&
        !editOptionCopy &&
        !window.confirm(stepConfirmationMessage)
      ) {
        setIsLoading(false);
        return;
      }
      if (
        isAddingStep &&
        !window.confirm(`Do you want to add  ${editStepCopy.name}?`)
      ) {
        setIsLoading(false);
        console.log(editOptionCopy);
        return;
      }
      if (editOptionCopy?.name.toLowerCase() === "none") {
        throw new Error(
          "Cannot add this option. Please use the checkbox to enable or disable 'None' option for Part Selector type"
        );
      }
      if (editOptionCopy && !window.confirm(optionConfirmationMessage)) {
        setIsLoading(false);
        return;
      }
      let editedStep = { ...editStepCopy };
      if (isAddingStep) {
        if (checkIfStepNameExists(editedStep)) {
          throw new Error(
            "An interaction with the same name already exists! Please enter another name"
          );
        }
        if (editedStep.type === "dimension_selector") {
          if (!editedStep.selector_type) {
            alert("Please choose dimension selector type");
            return;
          } else {
            if (editedStep.selector_type === "multiple") {
              editedStep.options = [
                {
                  name: "Length",
                  required: false,
                  dropDown: true,
                  options: [],
                },
                {
                  name: "Width",
                  required: false,
                  dropDown: true,
                  options: [],
                },
                {
                  name: "Depth",
                  required: false,
                  dropDown: true,
                  options: [],
                },
              ];
            } else {
              editedStep.options = [
                {
                  name: "Dimensions",
                  required: false,
                  dropDown: true,
                  options: [],
                },
              ];
            }
          }
        } else if (editedStep.type !== "dimension_selector" && partNodeNames) {
          const tempNodes = partNodeNames.replaceAll(" ", "").split(",");
          editedStep.node = tempNodes;
        }
        await patchRules(editedStep);
        const parameters = {
          product_id: id,
          customization_name: editedStep.name,
          customization_id: editedStep.id,
          customization_type: editedStep.type,
          user_type: isEditMode ? "Admin" : "User",
        };
        await fireEvent("CREATE_CUSTOMIZATION", parameters);
        setPartNodeNames("");
      } else {
        if (editStepCopy?.type !== "dimension_selector") {
          if (
            editOptionCopy &&
            editOptionIndex !== undefined &&
            editOptionIndex >= 0
          ) {
            if (checkConditions()) {
              const editedOptions = [...editStepCopy.options];
              editedOptions[editOptionIndex] = editOptionCopy;

              editedStep.options = editedOptions;
              setEditStepCopy(editedStep);
              handleGoBack();
            }
          } else if (!editOptionIndex && editOptionCopy) {
            console.log("hello 2");
            if (checkConditions()) {
              const addOptionIndex = editStepCopy.options?.length || 0;
              const editedOptions =
                editStepCopy?.options?.length > 0
                  ? [...editStepCopy.options]
                  : [];
              editedOptions[addOptionIndex] = editOptionCopy;
              editedStep.options = editedOptions;
              setEditStepCopy(editedStep);
              handleGoBack();
            }
          } else {
            console.log("hello 3");
            await patchRules(editedStep);
            const parameters = {
              product_id: id,
              customization_name: editedStep.name,
              customization_id: editedStep.id,
              customization_type: editedStep.type,
              user_type: isEditMode ? "Admin" : "User",
            };
            await fireEvent("EDIT_CUSTOMIZATION", parameters);
          }
        } else {
          let tempOptions = [...editedStep.options];
          if (editOptionIndex !== undefined && editOptionIndex >= 0) {
            tempOptions[editOptionIndex!] = editOptionCopy;
          } else {
            if (!editOptionCopy?.name && dimensionType !== undefined) {
              const tempOptionCopy = { ...editOptionCopy, name: dimensionType };
              if (
                tempOptions.find((option: any) => option.name === dimensionType)
              ) {
                setDimensionType("Length");
                throw new Error(
                  `${dimensionType} already exists in the ${editStepCopy.name}`
                );
              } else {
                setDimensionType(undefined);
              }
              // setEditOptionCopy(tempOptionCopy);
              tempOptions = [...tempOptions, tempOptionCopy];
            }
          }
          editedStep.options = tempOptions;
          await patchRules(editedStep);
          const parameters = {
            product_id: id,
            customization_name: editedStep.name,
            customization_id: editedStep.id,
            customization_type: editedStep.type,
            user_type: isEditMode ? "Admin" : "User",
          };
          await fireEvent("EDIT_CUSTOMIZATION", parameters);
          dispatch(SET_EDITING_STEP(null));
        }
      }
    } catch (err) {
      alert(err);
      setIsLoading(false);
    }
  };

  const partAndMaterialHandleChangeOption = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.name === "pid" || e.target.name === "textureUrl") {
      setEditOptionCopy({
        ...editOptionCopy,
        rules: [
          { ...editOptionCopy.rules[0], [e.target.name]: e.target.value },
        ],
      });
    } else {
      setEditOptionCopy({
        ...editOptionCopy,
        [e.target.name]: e.target.value,
      });
    }
  };

  const dimensionHandleChangeOption = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => { };

  const partAndMaterialHandleChangeStep = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    let editedStep;
    if (e.target.name === "custom_json") {
      editedStep = {
        ...editStepCopy,
        custom_json: e.target.value,
      };
    } else if (e.target.name === "name") {
      editedStep = {
        ...editStepCopy,
        name: e.target.value,
        id: editStepCopy?.id ? editStepCopy?.id : e.target.value.toLowerCase(),
      };
    } else if (e.target.name === "type") {
      editedStep = { ...editStepCopy, type: e.target.value };
      if (e.target.value !== "dimension_selector") {
        editedStep.node = [];
        if (e.target.value === "part_selector") {
          editedStep.enableNoneOption = false;
          editedStep.options = [
            {
              name: "None",
              thumbnail: "https://i.ibb.co/whY06SN/none.jpg",
              default: true,
              rules: [
                {
                  pid: "",
                },
              ],
            },
          ];
        } else if (e.target.value === "hide_show_part_selector") {
          editedStep.options = [
            {
              name: "hide",
              thumbnail: "https://i.ibb.co/whY06SN/none.jpg",
              default: false,
              rules: [{ pid: "" }],
            },
            {
              name: "show",
              thumbnail: "../assets/images/placeholder.png",
              default: false,
              rules: [{ pid: "" }],
            },
          ];
        } else {
          delete editedStep.enableNoneOption;
          editedStep.options = []
        }
      } else {
        delete editedStep?.node;
        delete editedStep.enableNoneOption;
      }
    } else if (e.target.name === "material" || e.target.name === "parts") {
      const tempStepWithoutId = JSON.parse(e.target.value);
      if (!stepIdRef.current.value) {
        stepIdRef.current.value = replaceSpaces(
          tempStepWithoutId.name
        ).toLowerCase();
      }
      const tempEditedStep = {
        ...tempStepWithoutId,
        ...(isAddingStep && { id: stepIdRef.current.value.toLowerCase() }),
      };

      tempEditedStep.options =
        e.target.name === "parts"
          ? [
            {
              name: "None",
              thumbnail: "https://i.ibb.co/whY06SN/none.jpg",
              default: true,
              rules: [
                {
                  pid: "",
                },
              ],
            },
            ...tempEditedStep.options,
          ]
          : [...tempEditedStep.options];
      editedStep = tempEditedStep;
    } else if (e.target.name === "selector_type") {
      editedStep = {
        ...editStepCopy,
        selector_type: e.target.value,
      };
    } else if (e.target.name === "custom_step_type") {
      editedStep = {
        ...editStepCopy,
        custom_step_type: e.target.value,
      };
    } else if (e.target.name === "id") {
      editedStep = {
        ...editStepCopy,
        id: e.target.value,
      };
    } else {
      editedStep = {
        ...editStepCopy,
        node: e.target.value.replaceAll(" ", "").split(","),
      };
    }

    setEditStepCopy(editedStep);
  };

  const handleImageChange = (name: "thumbnail" | "texture", url: string) => {
    const tempOption = { ...editOptionCopy };
    if (name === "thumbnail") {
      tempOption.thumbnail = url;
      setEditOptionCopy(tempOption);
    } else {
      tempOption.rules = [{ textureUrl: url }];
      setEditOptionCopy(tempOption);
    }
  };

  const handleToggleNoneOption = () => {
    const editedStep = { ...editStepCopy };
    editedStep.enableNoneOption = !editedStep.enableNoneOption;
    setEditStepCopy(editedStep);
  };

  const handleToggleisHidden = () => {
    const editedStep = { ...editStepCopy };
    editedStep.isHidden = editedStep.isHidden ? !editedStep.isHidden : true;
    setEditStepCopy(editedStep);
  };

  const handledefaultOption = (option: Option) => {
    const editedStepNew = { ...editStepCopy };
    editedStepNew.defaultOption = option;
    setEditStepCopy(editedStepNew);
  };

  const { updateUserConfiguration } = useUserConfiguration();

  const EnableExtraCustomisations = (props: any) => {
    const [enabledSteps, setEnabledSteps] = useState<any>(
      props.option?.enableExtraCustomisations?.enabledSteps ??
      editOptionCopy?.enableExtraCustomisations?.enabledSteps ??
      []
    );
    const [disabledSteps, setDisabledSteps] = useState<any>(
      props.option?.enableExtraCustomisations?.disabledSteps ??
      editOptionCopy?.enableExtraCustomisations?.disabledSteps ??
      []
    );

    const [executableSteps, setExecutableSteps] = useState<any>(
      props.option?.enableExtraCustomisations?.executableSteps ??
      editOptionCopy?.enableExtraCustomisations?.executableSteps ??
      []
    );

    useEffect(() => {
      productConfiguratorRules?.steps?.forEach((step: any) => {
        if (
          !executableSteps.find((stepOld: any) => stepOld.name === step.name)
        ) {
          setExecutableSteps(
            executableSteps.filter((stepOld: any) => stepOld.name !== step.name)
          );
        }
        if (!enabledSteps.find((stepOld: any) => stepOld.name === step.name)) {
          setEnabledSteps(
            enabledSteps.filter((stepOld: any) => stepOld.name !== step.name)
          );
        }
        if (!disabledSteps.find((stepOld: any) => stepOld.name === step.name)) {
          setDisabledSteps(
            disabledSteps.filter((stepOld: any) => stepOld.name !== step.name)
          );
        }
      });
    }, [disabledSteps, enabledSteps, executableSteps]);
    return (
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          display: "block",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 10px 20px",
          }}
        >
          <p>
            If user selects{" "}
            <b>
              <i>{props.option.name}</i>
            </b>{" "}
            under{" "}
            <b>
              <i>{editStepCopy.name}</i>
            </b>
          </p>
        </div>
        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",

            padding: " 10px 20px",
          }}
        >
          <div
            style={{
              margin: "20px 10px ",
            }}
          >
            <h6>Enable Customisations</h6>
            <select
              id="customisations"
              name="customisations"
              onChange={(e) => {
                if (disabledSteps.includes(e.target.value)) {
                  setDisabledSteps(
                    disabledSteps.filter((step: any) => step !== e.target.value)
                  );
                }
                setEnabledSteps([...enabledSteps, e.target.value]);
              }}
              className="w-100"
            >
              <option selected disabled>
                Select Customisations
              </option>
              {productConfiguratorRules?.steps?.map(
                (
                  step: {
                    name: string;
                  },
                  index: number
                ) => {
                  return (
                    <option key={index} value={step.name}>
                      {step.name}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "fit-content",
              padding: "5px",
              margin: "5px",
              textAlign: "center",
              flexWrap: "wrap",
            }}
          >
            {enabledSteps.map((step: any, index: number) => {
              return (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "fit-content",
                    borderRadius: "5px",
                    border: "1px solid #b8b8be",
                    padding: "5px",
                    margin: "5px",
                    textAlign: "center",
                  }}
                >
                  <span key={index} style={{}}>
                    {step}
                  </span>
                  <span
                    onClick={() => {
                      const tempEnabledSteps = [...enabledSteps];
                      tempEnabledSteps.splice(index, 1);
                      setEnabledSteps(tempEnabledSteps);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      padding: "0px 10px",
                      marginLeft: "10px",
                    }}
                  >
                    <img src={deleteIcon} style={{ width: "10px" }} alt="" />
                  </span>
                </span>
              );
            })}
          </div>

          <div
            style={{
              margin: "20px 10px",
            }}
          >
            <h6>Disable Customisations</h6>
            <select
              id="customisations"
              name="customisations"
              onChange={(e) => {
                if (enabledSteps.includes(e.target.value)) {
                  setEnabledSteps(
                    enabledSteps.filter((step: any) => step !== e.target.value)
                  );
                }
                setDisabledSteps([...disabledSteps, e.target.value]);
              }}
              className="w-100"
            >
              <option selected disabled>
                Select Customisations
              </option>
              {productConfiguratorRules?.steps?.map(
                (
                  step: {
                    name: string;
                  },
                  index: number
                ) => {
                  return (
                    <option key={index} value={step.name}>
                      {step.name}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "fit-content",
              padding: "5px",
              margin: "5px",
              textAlign: "center",
              flexWrap: "wrap",
            }}
          >
            {disabledSteps.map((step: any, index: number) => {
              return (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "fit-content",
                    borderRadius: "5px",
                    border: "1px solid #b8b8be",
                    padding: "5px",
                    margin: "5px",
                    textAlign: "center",
                  }}
                >
                  <span key={index} style={{}}>
                    {step}
                  </span>
                  <span
                    onClick={() => {
                      const tempDisabledSteps = [...disabledSteps];
                      tempDisabledSteps.splice(index, 1);
                      setDisabledSteps(tempDisabledSteps);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      padding: "0px 10px",
                      marginLeft: "10px",
                    }}
                  >
                    <img src={deleteIcon} style={{ width: "10px" }} alt="" />
                  </span>
                </span>
              );
            })}
          </div>

          <div
            style={{
              margin: "20px 10px",
            }}
          >
            <h6>Run Customisations</h6>

            <select
              id="type"
              name="type"
              onChange={(e) => {
                let tempExecutableSteps = [...executableSteps];
                tempExecutableSteps.push({
                  name: e.target.value,
                  option: {},
                });

                if (
                  executableSteps.find(
                    (step: any) => step.name === e.target.value
                  )
                ) {
                  tempExecutableSteps = executableSteps;
                }

                setExecutableSteps(tempExecutableSteps);
              }}
              className="w-100"
            >
              <option selected disabled>
                Select Customisations
              </option>
              {productConfiguratorRules?.steps?.map(
                // show the step if the type is part_selector or material_selector
                (
                  step: {
                    name: string;
                    type: string;
                  },
                  index: number
                ) => {
                  return step.type !== "dimension_selector" ? (
                    <option key={index} value={step.name}>
                      {step.name}
                    </option>
                  ) : null;
                }
              )}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              padding: "5px",
              margin: "5px auto",
              textAlign: "center",
              flexWrap: "wrap",
            }}
          >
            {executableSteps.map((step: any, index: number) => {
              const foundStep = productConfiguratorRules?.steps?.find(
                (stepOld: any) => stepOld.name === step.name
              );
              if (!foundStep?.options) {
                const tempExecutableSteps = [...executableSteps];
                tempExecutableSteps.splice(index, 1);
                setExecutableSteps(tempExecutableSteps);
              }

              return (
                <Card
                  style={{
                    width: "100%",
                    border: "1px solid #b8b8be",
                    padding: "5px",
                    margin: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <Card.Body className="text-start py-1 px-0 mx-2">
                    <Card.Title className="mb-4 w-100 d-flex justify-content-between align-items-center">
                      <h6>{step?.name}</h6>

                      <span
                        onClick={() => {
                          const tempExecutableSteps = [...executableSteps];
                          tempExecutableSteps.splice(index, 1);
                          setExecutableSteps(tempExecutableSteps);
                        }}
                      >
                        <img
                          src={deleteIcon}
                          style={{ width: "10px" }}
                          alt=""
                        />
                      </span>
                    </Card.Title>

                    <Col className="px-2">
                      <Row>
                        {foundStep?.options.length > 0 &&
                          foundStep?.options.map(
                            (option: any, index: number) => {
                              return (
                                <Col
                                  xs={3}
                                  className="d-flex flex-column align-items-center "
                                  onClick={async () => {
                                    let tempExecutableSteps = executableSteps;
                                    tempExecutableSteps =
                                      tempExecutableSteps.map(
                                        (stepNew: any) => {
                                          if (stepNew.name === step.name) {
                                            return {
                                              name: step.name,
                                              option: option,
                                            };
                                          } else {
                                            return stepNew;
                                          }
                                        }
                                      );
                                    setExecutableSteps(tempExecutableSteps);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={option.thumbnail}
                                    className={`rounded-circle border border-2 ${option.name ===
                                      executableSteps.find(
                                        (stepNew: any) =>
                                          stepNew.name === step.name
                                      ).option.name
                                      ? "border-dark border-3"
                                      : " "
                                      } ${index > 3 && "mt-3"}`}
                                    width={"55px"}
                                    height={"55px"}
                                    alt={option.name}
                                  />
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "center",
                                      color: "#717070",
                                      paddingTop: "5px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {option.name}
                                  </span>
                                </Col>
                              );
                            }
                          )}
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            marginTop: "-10px",
            borderTop: "1px solid #E5E5E5",
            width: "inherit",
            position:
              executableSteps.length > 0 ||
                enabledSteps.length > 0 ||
                disabledSteps.length > 0
                ? "sticky"
                : "absolute",
            bottom: "0",
          }}
        >
          <button
            onClick={() => {
              const tempOption = {
                ...editStepCopy.options.find(
                  (option: any) => option.name === props.option.name
                ),
              };
              tempOption.enableExtraCustomisations = {
                enabledSteps,
                disabledSteps,
                executableSteps,
              };
              const tempOptions = [...editStepCopy.options];
              tempOptions.splice(
                tempOptions.findIndex(
                  (option: any) => option.name === props.option.name
                ),
                1,
                tempOption
              );
              const tempStep = { ...editStepCopy };
              tempStep.options = tempOptions;
              setEditStepCopy(tempStep);
              console.log(editStepCopy);
              setEnableExtraCustomisations(false);
            }}
            className="btn border-0 btn-dark w-100"
            style={{ background: "#A2DB35", margin: "10px" }}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        dialogClassName="custom-modal"
        backdrop={useIsPortrait()}
        show={showModal}
        animation={false}
        centered
      >
        <Modal.Header className="border-0">
          <Modal.Title>
            <div className="d-flex align-items-center justify-content-center ">
              <button
                className="border-0 bg-transparent p-0 me-3"
                onClick={handleCloseModal}
              >
                <IoArrowBack size={"25px"} className="cursor-pointer" />
              </button>
              <h4 className="m-0">
                {isAddingStep
                  ? "Adding New Customization"
                  : editingOption
                    ? `Editing ${editingStep?.name} > ${editingOption.name}`
                    : `Editing ${editingStep?.name} `}
              </h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        {isAddingStep ? (
          <Modal.Body className="custom-modal-body">
            <Row className="mt-2 px-2 align-items-center mb-3">
              <Col xs={12} className="">
                <label
                  htmlFor="name"
                  className="m-0"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#6B778C",
                  }}
                >
                  Enter Customization name: *
                </label>
              </Col>
              <Col>
                <input
                  type="text"
                  className="pl-1 w-100"
                  id="name"
                  name="name"
                  onChange={(e) => {
                    partAndMaterialHandleChangeStep(e);
                  }}
                  value={editStepCopy?.name}
                />
              </Col>
            </Row>
            <Row className="mt-2 px-2 align-items-center mb-3">
              <Col xs={12} className="">
                <label
                  htmlFor="name"
                  className="m-0"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#6B778C",
                  }}
                >
                  Enter Customization ID : *
                </label>
              </Col>
              <Col>
                <input
                  ref={stepIdRef}
                  type="text"
                  className="pl-1 w-100"
                  id="id"
                  name="id"
                  onChange={(e) => {
                    partAndMaterialHandleChangeStep(e);
                  }}
                  value={
                    (editStepCopy?.id && editStepCopy.id) ||
                    (editStepCopy?.name &&
                      replaceSpaces(editStepCopy?.name).toLowerCase())
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2 px-2 align-items-center mb-3">
              <Col xs={12} className="">
                <label
                  htmlFor="type"
                  className="m-0"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#6B778C",
                  }}
                >
                  Select Customization Type: *
                </label>
              </Col>
              <Col>
                <select
                  id="type"
                  name="type"
                  onChange={(e) => {
                    partAndMaterialHandleChangeStep(e);
                  }}
                  className="w-100"
                >
                  <option selected disabled>
                    Select Type
                  </option>
                  {allStepTypes.map(
                    (
                      stepType: {
                        name: string;
                        value: string;
                      },
                      index: number
                    ) => {
                      return (
                        <option key={index} value={stepType.value}>
                          {stepType.name}
                        </option>
                      );
                    }
                  )}
                </select>
              </Col>
            </Row>

            {editStepCopy?.type &&
              editStepCopy?.type !== "dimension_selector" &&
              editStepCopy?.type !== "custom_step" &&
              isAddingStep && (
                <div>
                  {
                    <Row className="mt-2 px-2 align-items-center mb-3">
                      <Col xs={12} className="">
                        <label
                          htmlFor="node"
                          className="m-0"
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#6B778C",
                          }}
                        >
                          Enter{" "}
                          {editStepCopy?.type === "material_selector"
                            ? "material"
                            : "part"}{" "}
                          name/s:
                        </label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          className="pl-1 w-100"
                          id="node"
                          name="node"
                          onChange={(e) => {
                            setPartNodeNames(e.target.value);
                          }}
                          value={partNodeNames}
                        />
                      </Col>
                    </Row>
                  }
                  {editStepCopy?.type === "part_selector" && (
                    <Row className="mt-2 px-2 align-items-center mb-3">
                      <Col xs={12} className="">
                        <label
                          htmlFor="parts"
                          className="m-0"
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#6B778C",
                          }}
                        >
                          Pick Parts from list: *
                        </label>
                      </Col>
                      <Col>
                        <select
                          id="parts"
                          name="parts"
                          onChange={(e) => {
                            setSelectedAddingOption(
                              JSON.parse(e.target.value).name
                            );
                            partAndMaterialHandleChangeStep(e);
                          }}
                          className="w-100"
                        >
                          <option selected disabled>
                            Select
                          </option>
                          {productParts?.map((p: any, index: number) => {
                            return (
                              <option key={index} value={JSON.stringify(p)}>
                                {p.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>
                  )}
                  {editStepCopy?.type === "material_selector" && (
                    <Row className="mt-2 px-2 align-items-center mb-3">
                      <Col xs={12} className="">
                        <label
                          htmlFor="material"
                          className="m-0"
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#6B778C",
                          }}
                        >
                          Pick Material from list: *
                        </label>
                      </Col>
                      <Col>
                        <select
                          id="material"
                          name="material"
                          onChange={(e) => {
                            setSelectedAddingOption(
                              JSON.parse(e.target.value).name
                            );
                            partAndMaterialHandleChangeStep(e);
                          }}
                          className="w-100"
                        >
                          <option selected disabled>
                            Select
                          </option>
                          {productMaterials?.map((m: any, index: number) => {
                            return (
                              <option key={index} value={JSON.stringify(m)}>
                                {m.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>
                  )}

                  {enableExtraCustomisations && (
                    <EnableExtraCustomisations
                      option={extraCustomisationOption}
                    />
                  )}
                  {editStepCopy.type === "material_selector" ? (
                    <div>
                      {productMaterials?.filter(
                        (p: any) => p.name === selectedAddingOption
                      )[0] && (
                          <label
                            className="m-0"
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: "#6B778C",
                            }}
                          >
                            Select Default Option
                          </label>
                        )}
                      <Row className="mt-2 ps-2">
                        {productMaterials
                          ?.filter(
                            (p: any) => p.name === selectedAddingOption
                          )[0]
                          ?.options.map((p: any, index: number) => {
                            return (
                              <Col
                                key={p.name}
                                xs={3}
                                className="align-items-center "
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={p.thumbnail}
                                  className={`rounded-circle border border-2 ${index > 3 && "mt-3"
                                    }`}
                                  width={"55px"}
                                  height={"55px"}
                                  alt={p.name}
                                  style={
                                    editStepCopy?.defaultOption?.name === p.name
                                      ? {
                                        outline: "2px solid black",
                                      }
                                      : {}
                                  }
                                  onClick={() => {
                                    setEditStepCopy({
                                      ...editStepCopy,
                                      defaultOption: p,
                                    });
                                    handledefaultOption(p);
                                    dispatch(
                                      UPDATE_USER_CONFIGURATION({
                                        ...userConfigNew,
                                        [editStepCopy.id]: {
                                          name: p.name,
                                          value: p.lookup_id || "",
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                    color: "#717070",
                                    paddingTop: "5px",
                                    fontWeight: "700",
                                    width: "100%",
                                  }}
                                >
                                  {p.name}
                                </span>

                                <button
                                  className="btn btn-sm mt-2"
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                    backgroundColor: "#A2DA36",
                                    color: "white",
                                    paddingTop: "5px",
                                    fontWeight: "700",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    setEnableExtraCustomisations(true);
                                    setExtraCustomisationOption(p);
                                  }}
                                >
                                  ...
                                </button>
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  ) : (
                    <div>
                      {productParts?.filter(
                        (p: any) => p.name === selectedAddingOption
                      )[0] && (
                          <>
                            <label
                              className="ms-2"
                              style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "#6B778C",
                              }}
                            >
                              Select Default Option
                            </label>

                            <br />
                            <input
                              className="form-check-input ms-2 mt-3"
                              type="checkbox"
                              checked={editStepCopy?.enableNoneOption}
                              onChange={handleToggleNoneOption}
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label ms-2 mt-3"
                              htmlFor="flexCheckDefault"
                              style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "#6B778C",
                              }}
                            >
                              Add None as an option
                            </label>
                          </>
                        )}
                      <Row className="mt-4 ps-2">
                        {productParts
                          ?.filter(
                            (p: any) => p.name === selectedAddingOption
                          )[0]
                          ?.options.concat(
                            editStepCopy?.enableNoneOption
                              ? [
                                {
                                  name: "None",
                                  thumbnail:
                                    "https://i.ibb.co/whY06SN/none.jpg",
                                  default: true,
                                  rules: [
                                    {
                                      pid: "",
                                    },
                                  ],
                                },
                              ]
                              : []
                          )
                          .map((p: any, index: number) => {
                            return (
                              <Col
                                key={p.name}
                                xs={3}
                                className="align-items-center "
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={p.thumbnail}
                                  className={`rounded-circle border border-2 ${index > 3 && "mt-3"
                                    }`}
                                  width={"55px"}
                                  height={"55px"}
                                  alt={p.name}
                                  style={
                                    editStepCopy?.defaultOption?.name === p.name
                                      ? {
                                        outline: "2px solid black",
                                      }
                                      : {}
                                  }
                                  onClick={() => {
                                    setEditStepCopy({
                                      ...editStepCopy,
                                      defaultOption: p.name,
                                    });
                                    handledefaultOption(p);
                                    dispatch(
                                      UPDATE_USER_CONFIGURATION({
                                        ...userConfigNew,
                                        [editStepCopy.id]: {
                                          name: p.name,
                                          value: p.lookup_id || "",
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                    color: "#717070",
                                    paddingTop: "5px",
                                    fontWeight: "700",
                                    width: "100%",
                                  }}
                                >
                                  {p.name}
                                </span>
                                <button
                                  className="btn btn-sm mt-2"
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                    backgroundColor: "#A2DA36",
                                    color: "white",
                                    paddingTop: "5px",
                                    fontWeight: "700",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    setEnableExtraCustomisations(true);
                                    setExtraCustomisationOption(p);
                                  }}
                                >
                                  ...
                                </button>
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  )}
                </div>
              )}

            {enableExtraCustomisations && (
              <EnableExtraCustomisations option={extraCustomisationOption} />
            )}
            {editStepCopy?.type === "dimension_selector" && isAddingStep && (
              <div>
                <Row className="mt-2 px-2 align-items-center mb-3">
                  <Col xs={12} className="">
                    <label
                      htmlFor="selector_type"
                      className="m-0"
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#6B778C",
                      }}
                    >
                      Pick Dimension Selector type: *
                    </label>
                  </Col>
                  <Col>
                    <select
                      id="selector_type"
                      name="selector_type"
                      onChange={(e) => {
                        partAndMaterialHandleChangeStep(e);
                      }}
                      className="w-100"
                    >
                      <option selected disabled>
                        Select
                      </option>
                      {allDimensionSelectorTypes?.map(
                        (m: any, index: number) => {
                          return (
                            <option key={index} value={m.value}>
                              {m.name}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </Col>
                </Row>
              </div>
            )}
            {editStepCopy?.type === "custom_step" && isAddingStep && (
              <div>
                <div>
                  <Row className="mt-2 px-2 align-items-center mb-3">
                    <Col xs={12} className="">
                      <label
                        htmlFor="custom_step_type"
                        className="m-0"
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#6B778C",
                        }}
                      >
                        Pick Custom Step Selector type: *
                      </label>
                    </Col>
                    <Col>
                      <select
                        id="custom_step_type"
                        name="custom_step_type"
                        onChange={(e) => {
                          partAndMaterialHandleChangeStep(e);
                        }}
                        className="w-100"
                      >
                        <option selected disabled>
                          Select
                        </option>
                        {allDimensionSelectorTypes?.map(
                          (m: any, index: number) => {
                            return (
                              <option key={index} value={m.value}>
                                {m.name}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </Col>
                  </Row>
                  <Col className="p-0 ">
                    <h6 className="m-0">Add Options*</h6>
                  </Col>
                  <Row className="mt-2 px-2 align-items-center mb-3">
                    <Col xs={12} className="">
                      <label
                        htmlFor="name"
                        className="m-0"
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#6B778C",
                        }}
                      >
                        Enter Option Name : *
                      </label>
                    </Col>
                    <Col>
                      <input
                        ref={stepIdRef}
                        type="text"
                        className="pl-1 w-100"
                        id="option_name"
                        name="option_name"
                        onChange={(e) => {
                          onCustomOptionChange(e);
                        }}
                        value={customOption.name}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2 px-2 align-items-center mb-3">
                    <Col xs={12} className="">
                      <label
                        htmlFor="name"
                        className="m-0"
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#6B778C",
                        }}
                      >
                        Enter Thumbnail URL : *
                      </label>
                    </Col>
                    <Col>
                      <input
                        type="text"
                        className="pl-1 w-100"
                        id="option_thumbnail"
                        name="option_thumbnail"
                        onChange={(e) => {
                          onCustomOptionChange(e);
                        }}
                        value={customOption.thumbnail}
                      />
                    </Col>
                  </Row>
                  <Button style={{ background: "#A2DB35", border: "none" }} className="btn-success" onClick={onAddCustomOption}>
                    Add Option
                  </Button>
                </div>
                <div>
                  <Col className="py-2 ">
                    <h5 className="m-0">Options</h5>
                  </Col>
                  <Col className="p-0 d-flex flex-wrap">
                    {
                      editStepCopy.options.length > 0 && editStepCopy.options.map((option: any) => {
                        return <div className="d-flex flex-column align-items-center justify-content-center me-2">
                          <img className="rounded-circle border border-dark" width={"60px"} height={"60px"} src={option.thumbnail} alt={option.thumbnail} />
                          <p style={{ fontSize: "14px" }}>{option.name}</p>
                        </div>
                      })
                    }
                  </Col>
                </div>
              </div>
            )}
          </Modal.Body>
        ) : (
          <Modal.Body className="overflow-y-auto">
            {editOptionCopy === null ? (
              <div>
                <Row className="mt-2 px-2 align-items-center mb-3">
                  <Col xs={3} className="p-0 ">
                    <label htmlFor="name" className="m-0">
                      Name* :
                    </label>
                  </Col>
                  <Col>
                    <input
                      type="text"
                      className="pl-1 w-100"
                      id="name"
                      name="name"
                      onChange={(e) => {
                        partAndMaterialHandleChangeStep(e);
                      }}
                      value={editStepCopy?.name}
                    />
                  </Col>
                </Row>
                {editStepCopy?.id && (
                  <Row className="mt-2 px-2 align-items-center mb-3">
                    <Col xs={3} className="p-0 ">
                      <label htmlFor="name" className="m-0">
                        ID :
                      </label>
                    </Col>
                    <Col>
                      <input
                        type="text"
                        className="pl-1 w-100"
                        id="name"
                        name="name"
                        value={editStepCopy?.id}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
                {editStepCopy?.type !== "dimension_selector" && (
                  <div>
                    {editStepCopy?.type !== "custom_step" && <Row className="mt-2 px-2 align-items-center mb-3">
                      <Col xs={3} className="p-0 ">
                        <label htmlFor="nodes" className="m-0">
                          Node(s)* :
                        </label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          className="pl-1 w-100"
                          id="node"
                          name="node"
                          onChange={(e) => {
                            partAndMaterialHandleChangeStep(e);
                          }}
                          value={
                            editStepCopy?.node &&
                              typeof editStepCopy?.node === "object"
                              ? editStepCopy?.node.join(",")
                              : editStepCopy?.node ?? ""
                          }
                        />
                      </Col>
                    </Row>}
                    {
                      editStepCopy?.type === "custom_step" && (
                        <Row className="mt-2 px-2 align-items-center mb-3">
                          <Col xs={3} className="p-0 ">
                            <label htmlFor="name" className="m-0">
                              Custom Step Type :
                            </label>
                          </Col>
                          <Col>
                            <input
                              type="text"
                              className="pl-1 w-100"
                              id="name"
                              name="name"
                              value={editStepCopy?.custom_step_type ?? "multiple"}
                              disabled
                            />
                          </Col>
                        </Row>
                      )
                    }
                    <Row className="mt-2 px-2 align-items-center mb-3">
                      <Col xs={3} className="p-0 ">
                        <label htmlFor="nodes" className="m-0">
                          Custom JSON :
                        </label>
                      </Col>
                      <Col>
                        {/* <input
                          type="text"
                          className="pl-1 w-100"
                          id="custom_json"
                          name="custom_json"
                          onChange={(e) => {
                            partAndMaterialHandleChangeStep(e);
                          }}
                          // value={
                          //   editStepCopy?.node &&
                          //   typeof editStepCopy?.node === "object"
                          //     ? editStepCopy?.node.join(",")
                          //     : editStepCopy?.node ?? ""
                          // }
                        /> */}
                        <textarea
                          id="custom_json"
                          style={{
                            minHeight: "50px",
                            maxHeight: "100px",
                            padding: "0.5rem",
                            width: "100%",
                          }}
                          name="custom_json"
                          onChange={(e) => {
                            partAndMaterialHandleChangeStep(e);
                          }}
                          value={editStepCopy?.custom_json ?? ""}
                        />
                      </Col>
                    </Row>
                    {/* <Row className="mt-2 px-2 align-items-center mb-3">
                  <Col xs={3} className="p-0 ">
                    <label htmlFor="type" className="m-0">
                      Type :
                    </label>
                  </Col>
                  <Col>
                    {
                      allStepTypes.find((t) => t.value === editStepCopy?.type)
                        ?.name
                    }
                  </Col>
                </Row> */}

                    {/* <div className="d-flex justify-content-between align-items-center mb-4">
                  <h5 className="text-center">Options</h5>
                  {editStepCopy?.type === "dimension_selector" && (
                    <button
                      onClick={handleAddStepOption}
                      className="btn btn-dark"
                      style={{
                        background: "rgba(9, 30, 66, 0.04)",
                        fontWeight: "600",
                        border: "none",
                        color: "#42526E",
                      }}
                    >
                      Add Option
                    </button>
                  )}
                </div> */}

                    {editStepCopy?.type === "part_selector" && (
                      <div className="d-flex justify-content-start align-items-center mb-4 ms-2">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          checked={editStepCopy?.enableNoneOption}
                          onChange={handleToggleNoneOption}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label ms-3"
                          htmlFor="flexCheckDefault"
                        >
                          Add None as an option
                        </label>
                      </div>
                    )}
                    {editStepCopy?.type !== "hide_show_part_selector" && (
                      <div className="d-flex justify-content-start align-items-center mb-4 ms-2">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          checked={editStepCopy?.isHidden}
                          onChange={handleToggleisHidden}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label ms-3"
                          htmlFor="flexCheckDefault"
                        >
                          Hide Step
                        </label>
                      </div>
                    )}
                  </div>
                )}
                {editStepCopy?.type !== "hide_show_part_selector" && (
                  <>
                    {editStepCopy?.type === "part_selector" ||
                      editStepCopy?.type === "material_selector" ? (
                      <p>Select Default Option</p>
                    ) : (
                      <p></p>
                    )}
                    <Row className="mt-2 px-2 align-items-center mb-3 justify-content-between">
                      {editStepCopy?.options?.length > 0 &&
                        editStepCopy?.options
                          .filter((option: any) => option.name !== "None")
                          .concat(
                            editStepCopy?.enableNoneOption
                              ? [
                                {
                                  name: "None",
                                  thumbnail:
                                    "https://i.ibb.co/whY06SN/none.jpg",
                                  default: true,
                                  rules: [
                                    {
                                      pid: "",
                                    },
                                  ],
                                },
                              ]
                              : []
                          )
                          .map((option: any, index: number) => {
                            const { name, thumbnail, options } = option;
                            return editStepCopy.type ===
                              "dimension_selector" ? (
                              editStepCopy.selector_type === "multiple" ? (
                                <Col
                                  xs={12}
                                  key={index}
                                  className={` ${index > 1 && "mt-3"} px-0`}
                                >
                                  <div className="d-flex flex-column align-items-center border-0 rounded-xl my-2 py-2 px-2">
                                    <div className="d-flex w-100 justify-content-between mb-3">
                                      <p
                                        className="mb-0 h6"
                                        style={{ color: "#6B778C" }}
                                      >
                                        {name}
                                      </p>
                                      {/* <div className="d-flex ">
                                  <button
                                    className="bg-transparent border-0 m-0 p-0 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      handleEditOption(option, index);
                                    }}
                                  >
                                    <img src={editIcon} alt="editIcon" />
                                  </button>
                                  <button
                                    className="bg-transparent border-0 m-0 p-0 ms-3 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      handleDeleteOption(index);
                                    }}
                                  >
                                    <FiTrash2 />
                                  </button>
                                </div> */}
                                    </div>
                                    <div className="w-100">
                                      <div>
                                        <Row className="mt-2 px-2 align-items-center mb-3">
                                          <Col>
                                            <input
                                              type="text"
                                              className="pl-1 w-100"
                                              id={name + "nameInput"}
                                              name={name + "-nameInput"}
                                              placeholder={`Enter name`}
                                              onChange={(e) => {
                                                handleChangeNewDimensionDetails(
                                                  e
                                                );
                                              }}
                                              value={dimensionObject[name].name}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mt-2 px-2 align-items-center mb-3">
                                          <Col>
                                            <input
                                              type="text"
                                              className="pl-1 w-100"
                                              id={name + "valueInput"}
                                              name={name + "-valueInput"}
                                              placeholder={`enter value`}
                                              onChange={(e) => {
                                                handleChangeNewDimensionDetails(
                                                  e
                                                );
                                              }}
                                              value={
                                                dimensionObject[name].value
                                                  ? dimensionObject[name].value
                                                  : ""
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mt-2 px-2 align-items-center mb-3">
                                          <Col>
                                            <button
                                              onClick={() => {
                                                handleAddNewDimension(name);
                                              }}
                                              className="btn btn-dark w-100"
                                              style={{
                                                background:
                                                  "rgba(9, 30, 66, 0.04)",
                                                fontWeight: "600",
                                                border: "none",
                                                color: "#42526E",
                                              }}
                                            >
                                              Add Option
                                            </button>
                                          </Col>
                                        </Row>
                                      </div>
                                      <Row className="">
                                        {options.map(
                                          (op: any, index: number) => {
                                            return (
                                              <Col
                                                key={op.name + index}
                                                className="my-2"
                                                xs={12}
                                              >
                                                <div className="border rounded px-2 py-1 d-flex justify-content-between align-items-center">
                                                  <p className="m-0 p-0">
                                                    {op.value}
                                                  </p>
                                                  <div
                                                    className="d-flex justify-content-between align-items-center"
                                                    onClick={() =>
                                                      handleDeleteNewDimensionOption(
                                                        name,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={deleteIcon}
                                                      alt="delete"
                                                      width={"12px"}
                                                    />
                                                  </div>
                                                </div>
                                              </Col>
                                            );
                                          }
                                        )}
                                      </Row>

                                      {/* )
                                )} */}
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                <Col
                                  xs={12}
                                  key={index}
                                  className={` ${index > 1 && "mt-3"} px-0`}
                                >
                                  <div className="d-flex flex-column align-items-center border-0 rounded-xl my-2 py-2 px-2">
                                    <div className="d-flex w-100 justify-content-between mb-3">
                                      <p
                                        className="mb-0 h6"
                                        style={{ color: "#6B778C" }}
                                      >
                                        {name}
                                      </p>
                                    </div>
                                    <div className="w-100">
                                      <div>
                                        <Row className="mt-2 px-2 align-items-center mb-3">
                                          <Col>
                                            <input
                                              type="text"
                                              className="pl-1 w-100"
                                              id={name + "nameInput"}
                                              name={name + "-nameInput"}
                                              placeholder={`Enter name`}
                                              onChange={(e) => {
                                                handleChangeNewDimensionDetails(
                                                  e
                                                );
                                              }}
                                              value={dimensionObject[name].name}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mt-2 px-2 align-items-center mb-3">
                                          <Col>
                                            <input
                                              type="text"
                                              className="pl-1 w-100"
                                              id={name + "valueInput"}
                                              name={name + "-valueInput"}
                                              placeholder={`enter value`}
                                              onChange={(e) => {
                                                handleChangeNewDimensionDetails(
                                                  e
                                                );
                                              }}
                                              value={
                                                dimensionObject[name].value
                                                  ? dimensionObject[name].value
                                                  : ""
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mt-2 px-2 align-items-center mb-3">
                                          <Col>
                                            <button
                                              onClick={() => {
                                                handleAddNewDimension(name);
                                              }}
                                              className="btn btn-dark w-100"
                                              style={{
                                                background:
                                                  "rgba(9, 30, 66, 0.04)",
                                                fontWeight: "600",
                                                border: "none",
                                                color: "#42526E",
                                              }}
                                            >
                                              Add Option
                                            </button>
                                          </Col>
                                        </Row>
                                      </div>
                                      <Row className="">
                                        {options.map(
                                          (op: any, index: number) => {
                                            return (
                                              <Col
                                                key={op.name + index}
                                                className="my-2"
                                                xs={12}
                                              >
                                                <div className="border rounded px-2 py-1 d-flex justify-content-between align-items-center">
                                                  <p className="m-0 p-0">
                                                    {op.value}
                                                  </p>
                                                  <div
                                                    className="d-flex justify-content-between align-items-center"
                                                    onClick={() =>
                                                      handleDeleteNewDimensionOption(
                                                        name,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={deleteIcon}
                                                      alt="delete"
                                                      width={"12px"}
                                                    />
                                                  </div>
                                                </div>
                                              </Col>
                                            );
                                          }
                                        )}
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              )
                            ) : (
                              <Col
                                xs={6}
                                key={index}
                                className={` ${index > 1 && "mt-3"}`}
                              >
                                {enableExtraCustomisations && (
                                  <EnableExtraCustomisations
                                    option={extraCustomisationOption}
                                  />
                                )}
                                {/* {
                                  executableStepEnabled && (
                                    <ExecutableStep
                                    executableStep={executableStepEnabled}
                                    />
                                  )
                                } */}
                                <div
                                  className="d-flex align-items-center justify-content-between border rounded-xl py-2 px-2"
                                  style={
                                    editStepCopy?.defaultOption?.name === name
                                      ? {
                                        outline: "2px solid black",
                                        borderRadius: "5px",
                                      }
                                      : {}
                                  }
                                  onClick={() => {
                                    setEditStepCopy({
                                      ...editStepCopy,
                                      defaultOption: option,
                                    });
                                    handledefaultOption(option);
                                    updateUserConfiguration(
                                      editStepCopy?.name,
                                      name
                                    );
                                  }}
                                >
                                  <p className="mb-0">{name}</p>
                                  {editStepCopy?.type !==
                                    "dimension_selector" && (
                                      <img
                                        src={thumbnail}
                                        alt={name}
                                        width={"50px"}
                                        height={"50px"}
                                        style={{ objectFit: "cover" }}
                                        className="border border-secondary rounded-circle"
                                      />
                                    )}
                                </div>
                                <button
                                  className="btn btn-sm mt-2"
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                    backgroundColor: "#A2DA36",
                                    color: "white",
                                    paddingTop: "5px",
                                    fontWeight: "700",
                                    width: "100%",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEnableExtraCustomisations(true);
                                    console.log(option);
                                    setExtraCustomisationOption(option);
                                  }}
                                >
                                  ...
                                </button>
                              </Col>
                            );
                          })}
                    </Row>
                  </>
                )}
              </div>
            ) : editStepCopy?.type === "dimension_selector" ? (
              <AddOrEditDimensionOptionForm
                dimensionHandleChangeOption={dimensionHandleChangeOption}
                editOptionCopy={editOptionCopy}
                editStepCopy={editStepCopy}
                setEditOptionCopy={setEditOptionCopy}
                setDimensionType={setDimensionType}
                dimensionType={dimensionType}
                isAddingDimension={isAddingDimension}
                setIsAddingDimension={setIsAddingDimension}
              />
            ) : (
              <AddOrEditPartMaterialOptionForm
                partAndMaterialHandleChangeOption={
                  partAndMaterialHandleChangeOption
                }
                handleImageChange={handleImageChange}
                editOptionCopy={editOptionCopy}
                editStepCopy={editStepCopy}
              />
            )}
          </Modal.Body>
        )}
        {!enableExtraCustomisations && (
          <Modal.Footer className="d-flex justify-content-between">
            {editOptionCopy === null ? (
              <Button
                style={{
                  background: "rgba(9, 30, 66, 0.04)",
                  fontWeight: "600",
                  border: "none",
                  color: "#42526E",
                }}
                variant="danger"
                onClick={async () => await handleDeleteStep(editingStepIndex!)}
                disabled={isAddingStep}
              >
                Delete
              </Button>
            ) : (
              <Button variant="danger" onClick={handleGoBack}>
                Cancel
              </Button>
            )}
            <Button
              style={{ background: "#A2DB35", border: "none" }}
              variant="dark"
              type="submit"
              onClick={handleSave}
            >
              Save Customization
            </Button>
          </Modal.Footer>
        )}
        {isLoading && (
          <div className="position-fixed h-100 w-100 start-0 top-0 bg-black opacity-50 d-flex justify-content-center align-items-center">
            <h1 className="d-flex justify-content-center align-items-center text-white">
              Loading...
            </h1>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ModalContainer;
