import React from "react";
import Col from "react-bootstrap/Col";

const CustomError: React.FC = () => {
  return (
    <>
      <h4>
        <title>{`Uh Oh! Can't find this page | HelloAR`}</title>
      </h4>
      <Col className="py-5">
        <h2>404 Error</h2>
        <p>Sorry, this page could not be found :(</p>
      </Col>
    </>
  );
};

export default CustomError;
