import axios from "axios";
import { Order } from "../types";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const postOrder = async (order: Order) => {
  let auth;
  if (process.env.REACT_APP_HELLOAR_STAGING === "true") {
    auth =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2Njg0OTA5MTEsImFjY291bnRfaWQiOiI2MWJhZWRjN2UxZGI1MmUwNmZjY2RiN2YiLCJzZXNzaW9uX2lkIjoiNjM3MzI2OWY1NWE1ZDI0NTlkZmZmM2RhIiwidXNlcl9uYW1lIjoic2FuZGVlcEBoZWxsb2FyLmlvIn0.D3n7gMtr4B-AG6pyWGhMIedaEMQEISaHB8te0LZMX9w";
  } else {
    auth =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODA2ODUzMDAsImFjY291bnRfaWQiOiI2M2ZjNDQ2ZTExNTczOWE0MWM5MDYxMzQiLCJzZXNzaW9uX2lkIjoiNjQyZDM4ZjQxMGNlNWNiNzI3MzQ0NjIzIiwidXNlcl9uYW1lIjoic2FuZGVlcCtmbGN1c3RvbWl6ZXJAaGVsbG9hci5pbyJ9.p6Jaw2fJEpfauelGYpahUIKl1xnC0V5UJZkMH4D3Ab0";
  }
  try {
    const res = await axios.post(baseUrl + "/viewer_orders", order, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Token ${auth}`,
      },
    });
    return res.data._id;
  } catch (error) {
    alert(`Unable to post order due to error: ${JSON.stringify(error)}`);
    console.error(error);
  }
};

export const sendOrderOtp = async (
  phoneNumber: string,
  orderResponseId: string
) => {
  return await axios
    .post(baseUrl + "/auth/order/phone/send_otp", {
      phone_number: phoneNumber,
      order: orderResponseId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const verifyOrderOtp = async (requestId: string, otp: string) => {
  return await axios.post(baseUrl + "/auth/order/phone/verify_otp", {
    request_id: requestId,
    otp: parseInt(otp),
  });
};
