import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchApplicationConfiguration } from "../api/api";
import ModalContainer from "../components/ModalContainer";
import { useAppSelector } from "../hooks/hooks";
import Header from "../Layouts/Header";
import Preview from "../Layouts/Preview";
import SideBar from "../Layouts/SideBar";
import {
  SET_APPLICATION_CONFIGURATION,
  SET_PRODUCT_MATERIALS,
  SET_PRODUCT_PARTS,
} from "../store/product/productSlice";
import {
  getPartsAndMaterials,
  isUserLoggedIn,
  userConstants,
} from "../utils/utils";

interface ConfiguratorProps {
  isEditMode?: boolean;
  sessionId: string;
}

const Configurator: React.FC<ConfiguratorProps> = ({
  isEditMode,
  sessionId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productViewerRef = useRef<undefined | any>();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const hostName = window.location.hostname;

  const configData = useAppSelector((state) => state.product.configuratorRules);
  const product = useAppSelector((state) => state.product.product);
  useEffect(() => {
    if (isEditMode) {
      if (!isUserLoggedIn()) {
        if (process.env.REACT_APP_HELLOAR_STAGING === "true") {
          if (hostName === "localhost") {
            navigate(`/login/${id}`);
          } else {
            alert("Please login to dev app to use this feature");
            window.location.replace("https://dev.app.helloar.io/login");
          }
        } else {
          alert("Please login to use this feature");
          window.location.replace("https://app.helloar.io/login");
        }
      }
      if (hostName === "localhost") {
        const user = localStorage.getItem("user");
        if (user) {
          dispatch({
            type: userConstants.SET_USER,
            payload: JSON.parse(user),
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEditMode, navigate]);

  useEffect(() => {
    if (product) {
      const getApplicationConfig = async () => {
        const res = await fetchApplicationConfiguration(product.application);
        if (res?.data?._items) {
          dispatch(SET_APPLICATION_CONFIGURATION(res.data._items));
        }
      };
      (async () => {
        const partsAndMaterials:
          | undefined
          | { parts: any[]; materials: any[] } = await getPartsAndMaterials(
          product?.application
        );
        await getApplicationConfig();
        if (partsAndMaterials) {
          const { parts, materials } = partsAndMaterials;
          dispatch(SET_PRODUCT_MATERIALS(materials));
          dispatch(SET_PRODUCT_PARTS(parts));
        }
      })();
    }
  }, [dispatch, product]);

  const enableHeader = searchParams.get("enableAddtoCart")
    ? JSON.parse(searchParams.get("enableAddtoCart")!)
    : false;
  const [isBottomBarOpen, setIsBottomBarOpen] = useState(false); //only for mobile screens
  return (
    <div
      className=" overflow-hidden position-relative configurator"
      style={{ height: "100vh" }}
    >
      {product && !isEditMode && (
        <Header
          productViewerRef={productViewerRef}
          sessionId={sessionId}
          isEditMode={isEditMode}
          product={product}
          enableHeader={enableHeader}
        />
      )}
      {configData && (
        <SideBar
          configData={configData}
          isBottomBarOpen={isBottomBarOpen}
          setIsBottomBarOpen={setIsBottomBarOpen}
          isEditMode={isEditMode}
          sessionId={sessionId}
        />
      )}
      <Preview
        productViewerRef={productViewerRef}
        isBottomBarOpen={isBottomBarOpen}
        configData={configData}
      />
      <ModalContainer isEditMode={isEditMode} />
    </div>
  );
};

export default Configurator;
