import { userConstants } from "../../utils/utils";

type UserState = {
  account_id: string;
  first_name: string;
  last_name: string;
  email: string;
  token: string;
  isAuthenticated: boolean;
  verified: boolean;
  role?: number;
};

const initialState: UserState = {
  account_id: "",
  first_name: "",
  last_name: "",
  email: "",
  token: "",
  isAuthenticated: false,
  verified: false,
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case userConstants.SET_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        firstName: "",
        lastName: "",
        email: "",
        token: "",
        isAuthenticated: false,
        profilePicture: "",
        verified: false,
      };
    default:
      return state;
  }
};

export default userReducer;
