import React from "react";
import { Col } from "react-bootstrap";
import useUserConfiguration from "../utils/updateUserConfiguration";

interface DropDownProps {
  name: string;
  options: any;
  dropDownIndex: number;
  parent: any;
  stepIndex?: number;
  selectorType?: "single" | "multiple";
}

const DropDown: React.FC<DropDownProps> = ({
  name,
  options,
  dropDownIndex,
  selectorType,
  stepIndex,
  parent,
}) => {
  const { updateUserConfigurationDropdown } = useUserConfiguration();
  const handleChange = (
    parentStepIndex: number | undefined,
    selectedOptionValue: any
  ) => {
    const selectedOption = options.find(
      (op: any) => op.value === selectedOptionValue
    );

    updateUserConfigurationDropdown(
      parentStepIndex,
      selectedOption,
      parent.name
    );
    // updateUserConfiguration(subStepName, optionValue, parentStepIndex);
  };
  return (
    <Col xs={6} className={`${dropDownIndex > 1 && "mt-3"}`}>
      <h6 className="mb-1" style={{ color: "#6B778C" }}>
        {name}
      </h6>
      <select
        name={name}
        onChange={(e) => handleChange(stepIndex, e.target.value)}
        id=""
        className=" px-2 py-1 rounded-1"
      >
        <option selected disabled>
          {/* {`Pick ${name}`} */}
          Select
        </option>
        {options.map(
          (
            option: { name: string; value: number; default?: boolean },
            index: number
          ) => {
            return (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            );
          }
        )}
      </select>
    </Col>
  );
};

export default DropDown;
