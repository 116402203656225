import { createSlice } from "@reduxjs/toolkit";
import {
  ConfiguratorRulesType,
  HelloARProduct,
  ProductMaterialType,
  ProductPartType,
} from "../../types";

const initialState: {
  product: HelloARProduct | undefined;
  configuratorRules: ConfiguratorRulesType | undefined;
  productParts: ProductPartType[] | undefined;
  productMaterials: ProductMaterialType[] | undefined;
  applicationConfiguration: any;
} = {
  product: undefined,
  configuratorRules: undefined,
  productParts: undefined,
  productMaterials: undefined,
  applicationConfiguration: undefined,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    SET_PRODUCT(state, action) {
      state.product = action.payload;
    },
    SET_CONFIGURATOR_RULES(state, action) {
      state.configuratorRules = action.payload;
    },
    SET_PRODUCT_PARTS(state, action) {
      state.productParts = action.payload;
    },
    SET_PRODUCT_MATERIALS(state, action) {
      state.productMaterials = action.payload;
    },
    SET_APPLICATION_CONFIGURATION(state, action) {
      state.applicationConfiguration = action.payload;
    },
  },
});

export const {
  SET_PRODUCT,
  SET_CONFIGURATOR_RULES,
  SET_PRODUCT_PARTS,
  SET_PRODUCT_MATERIALS,
  SET_APPLICATION_CONFIGURATION,
} = productSlice.actions;
export default productSlice.reducer;
