import axios, { AxiosRequestConfig } from "axios";
import { ConfiguratorRulesType } from "../types";
import { getAuth } from "../utils/utils";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
export const patchProductConfiguratorRules = async (
  pid: string,
  data: ConfiguratorRulesType
) => {
  const patchData = {
    configurator_rules: {
      json: JSON.stringify(data),
    },
  };
  const authString = getAuth();
  const config: AxiosRequestConfig = {
    url: `${baseUrl}/application_products/${pid}`,
    method: "patch",
    data: patchData,
    headers: { Authorization: `Token ${authString}` },
  };
  if (authString) {
    try {
      const response = await axios.request(config);
      if (response) return true;
    } catch (err: any) {
      throw err.response ? err.response : "Can't connect to server :(";
    }
  } else {
    alert("You are not authorized to make changes");
  }
};

export const uploadContent = async (file: any) => {
  const authToken = getAuth();
  if (authToken) {
    const configGetS3Url = {
      url: `${baseUrl}/signed_file`,
      params: {
        file_name: file.name.replaceAll(" ", ""),
        file_type: file.type,
      },
      method: "get",
      headers: { Authorization: authToken },
    };
    try {
      const response = await axios.request(configGetS3Url);
      let formData = new FormData();
      let key;
      for (key in response.data.data.fields) {
        formData.append(key, response.data.data.fields[key]);
      }
      formData.append("file", file);
      const configUploadToS3 = {
        url: response.data.data.url,
        data: formData,
        method: "post",
      };
      await axios.request(configUploadToS3);
      return response.data.uploaded_url;
    } catch (err) {
      throw new Error("Error while uploading content" + JSON.stringify(err));
    }
  } else {
    alert("You are not authorized to make changes");
  }
};

export const getParts = async (applicationId: string) => {
  const url = `${baseUrl}/parts`;
  const authToken = getAuth();
  const whereQuery = {
    application: applicationId,
  };
  if (authToken) {
    const partsData = await axios.get(url, {
      params: { where: whereQuery },
      headers: { Authorization: `Token ${authToken}` },
    });
    return partsData.data._items;
  } else {
    return undefined;
  }
};

export const getPart = async (applicationID: string, partId: string) => {
  const url = `${baseUrl}/parts/${partId}`;

  const partData = await axios.get(url);
  return partData.data;
};

export const getMaterials = async (applicationId: string) => {
  const url = `${baseUrl}/materials`;
  const authToken = getAuth();
  const whereQuery = {
    application: applicationId,
  };
  if (authToken) {
    const materials = await axios.get(url, {
      params: { where: whereQuery },
      headers: { Authorization: `Token ${authToken}` },
    });
    return materials.data._items;
  } else {
    return undefined;
  }
};

export const getMaterial = async (
  applicationID: string,
  materialId: string
) => {
  const url = `${baseUrl}/materials/${materialId}`;

  const materialData = await axios.get(url);
  return materialData.data;
};

export const getVariants = async (pid: string) => {
  const authToken = getAuth();
  const whereQuery = {
    product: pid,
  };
  const url = `${baseUrl}/variants`;
  if (authToken) {
    try {
      const variants = await axios.get(url, {
        params: { where: whereQuery },
        headers: { Authorization: `Token ${authToken}` },
      });
      return variants.data._items;
    } catch (error) {
      console.error(error);
    }
    // return materials.data._items;
  } else {
    return undefined;
  }
};
export const addEvent = async (patchData: {
  event: string;
  parameters: any;
}) => {
  const authToken = getAuth();
  const config: AxiosRequestConfig = {
    url: `${baseUrl}/app/session/event`,
    method: "post",
    data: patchData,
    headers: { Authorization: `Token ${authToken}` },
  };
  try {
    const response = await axios.request(config);
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchApplicationConfiguration = async (application: string) => {
  try {
    const authToken = getAuth();
    return axios.get(`${baseUrl}/application_configuration`, {
      params: {
        where: {
          application,
        },
      },
      headers: { Authorization: `Token ${authToken}` },
    });
  } catch (err: any) {
    throw err.response ? err.response : "Can't connect to server :(";
  }
};
